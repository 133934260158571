import { Box, ButtonBase, Popover, Stack } from "@mui/material";
import { bindPopover } from "material-ui-popup-state";
import { PopupState } from "material-ui-popup-state/core";
import React, { FC } from "react";

import { ReactComponent as UploadIcon } from "assets/icons/upload-icon.svg";

interface IAddButtonPopover {
  anchorEl: Element;
  popupState: PopupState;
}

const AddButtonPopover: FC<IAddButtonPopover> = ({ anchorEl, popupState }) => {
  return (
    <Popover
      {...bindPopover(popupState)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      sx={{
        mt: "5px",
      }}
    >
      <Stack direction="column" sx={{ width: 200, fontSize: 14, py: 0.5 }}>
        <Stack
          component={ButtonBase}
          py={1.3}
          px={1.5}
          justifyContent="flex-start"
          fontWeight={500}
          // onClick={onClick}
          sx={{
            color: (theme) => theme.palette.grey[700],

            svg: {
              width: 18,
              height: 18,
            },

            transition: "background-color 150ms ease-in-out",
            ":hover": {
              backgroundColor: (theme) => theme.palette.grey[200],
            },
          }}
          direction="row"
        >
          <Box sx={{ width: 20 }} mr={1.5}>
            <UploadIcon />
          </Box>
          <Box>
            Upload{" "}
            <Box
              component="span"
              color={(t) => t.palette.success.main}
              fontWeight="bold"
            >
              .csv
            </Box>
          </Box>
        </Stack>
      </Stack>
    </Popover>
  );
};

export default AddButtonPopover;
