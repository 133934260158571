import React from "react";

import Header from "components/pages/Header/Header";

const FinancesPage = () => {
  return (
    <>
      <Header label="Finances" />
    </>
  );
};

export default FinancesPage;
