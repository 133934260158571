import React, { FC } from "react";
import { Column } from "react-table";

import Table from "components/Table";
import { EmailFormatter } from "components/Table/formatters/EmailFormatter";

import { ClientFormatter } from "./components/ClientFormatter";
import { ClientStatusFormatter } from "./components/ClientStatusFormatter";

interface IClientsListTable {}

const ClientsListTable: FC<IClientsListTable> = () => {
  const columns: Column<any>[] = [
    {
      accessor: "client",
      Header: "Client",

      Cell: ({ value }) => <ClientFormatter name={value} />,

      padding: 0,
      width: 250,
    },
    {
      accessor: "status",
      Header: "Status",

      Cell: ({ value }) => <ClientStatusFormatter status={value} />,

      padding: 0,
      width: 150,
    },
    {
      accessor: "contactEmail",
      Header: "Contact Email",

      Cell: ({ value }) => <EmailFormatter email={value} />,

      padding: 0,
      width: 300,
    },
  ];

  return (
    <>
      <Table
        data={[
          {
            client: "Zerox Kompanija",
            contactEmail: "sample@zeroxdev.com",
            status: "lead",
          },
          {
            client: "Deckerdevs",
            contactEmail: "nicholas@deckerdevs.com",
            status: "client",
          },
          {
            client: "Toyota",
            contactEmail: "sample@toyota.com",
            status: "lost",
          },
          {
            client: "Zerox Kompanija",
            contactEmail: "sample@zeroxdev.com",
            status: "lead",
          },
          {
            client: "Deckerdevs",
            contactEmail: "nicholas@deckerdevs.com",
            status: "client",
          },
          {
            client: "Toyota",
            contactEmail: "sample@toyota.com",
            status: "lost",
          },
          {
            client: "Zerox Kompanija",
            contactEmail: "sample@zeroxdev.com",
            status: "lead",
          },
          {
            client: "Deckerdevs",
            contactEmail: "nicholas@deckerdevs.com",
            status: "client",
          },
          {
            client: "Toyota",
            contactEmail: "sample@toyota.com",
            status: "lost",
          },
          {
            client: "Zerox Kompanija",
            contactEmail: "sample@zeroxdev.com",
            status: "lead",
          },
          {
            client: "Deckerdevs",
            contactEmail: "nicholas@deckerdevs.com",
            status: "client",
          },
          {
            client: "Toyota",
            contactEmail: "sample@toyota.com",
            status: "lost",
          },
        ]}
        // data={data}
        columns={columns}
        // sort={sortOptions}
        // pagination={paginationOptions}
        // status="loading"
      />
    </>
  );
};

export default ClientsListTable;
