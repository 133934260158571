import React, { useState } from "react";
import ReactQuill from "react-quill";

const ProfileAboutMeEditor = () => {
  const [value, setValue] = useState("");

  return (
    <ReactQuill
      theme="snow"
      value={value}
      onChange={setValue}
      placeholder="Enter Bio"
    />
  );
};

export default ProfileAboutMeEditor;
