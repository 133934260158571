import { Box, Typography } from "@mui/material";
import React from "react";

const CompanyPage = () => {
  return (
    <Box sx={{ py: 4, px: 3 }}>
      <Typography variant="h4" mb={2}>
        Company
      </Typography>
    </Box>
  );
};

export default CompanyPage;
