import { KeyboardArrowDown, KeyboardArrowUp, Sort } from "@mui/icons-material";
import {
  Box,
  TableProps as MUITableProps,
  Stack,
  useTheme,
} from "@mui/material";
import { QueryStatus } from "@tanstack/react-query";
import { ReactNode, useEffect } from "react";
import {
  Row,
  TableOptions,
  useExpanded,
  useGlobalFilter,
  useGroupBy,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";

import { QueryParamsType } from "api/utils";

import TableBodyLoading from "./components/TableBodyLoading";
import TableEmptyMessage from "./components/TableEmptyMessage";
import TableErrorMessage from "./components/TableErrorMessage";
import TablePagination, {
  TablePageLimitType,
} from "./components/TablePagination";

export type TableSortingOptions = {
  disable?: boolean;
  remote?: {
    onSortChange: (sortBy: QueryParamsType["sort"]) => void;
  };
};

export type TablePaginationOptions = {
  remote?: {
    pageCount: number | undefined;
    onPageChange: (page: number, limit: TablePageLimitType) => void;
    totalResults?: number;
    initialPageSize?: TablePageLimitType;
  };
};

type TableProps<T extends object> = TableOptions<T> & {
  sort?: TableSortingOptions;
  pagination?: TablePaginationOptions;
  /**
   * Must be memoized, otherwise will reset pagination too frequently
   */
  filters?: unknown;
  status?: QueryStatus;
  // TODO: Improve typescript
  getRowProps?: (row: Row<T>) => object;
  size?: MUITableProps["size"];
  emptyMessage?: string | ReactNode;
  onSelectedRowsChanged?: (rows: Row<T>[]) => void;
};

const Table = <T extends object>({
  columns,
  data,
  initialState,
  // custom props
  sort,
  pagination,
  status,
  getRowProps,
  size,
  emptyMessage,
  filters,
  onSelectedRowsChanged,
  // rest
  ...rest
}: TableProps<T>) => {
  const theme = useTheme();
  // sorting
  const manualSortBy = Boolean(sort?.remote);
  const disableSortBy = Boolean(sort?.disable);
  const onSortChange = sort?.remote?.onSortChange;

  // pagination
  const remotePagination = Boolean(pagination?.remote);
  const remotePageCount = pagination?.remote?.pageCount;
  const onPageChange = pagination?.remote?.onPageChange;
  const initialPageSize = pagination?.remote?.initialPageSize;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    // pagination
    gotoPage,
    setPageSize,
    pageCount,
    // global filter
    setGlobalFilter,
    // state
    state: { sortBy, pageIndex, pageSize },
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
      manualGroupBy: true,
      // sorting
      disableSortBy,
      manualSortBy,
      disableMultiSort: true,
      // pagination
      manualPagination: remotePagination,
      pageCount: remotePageCount,
      // global filter,
      manualGlobalFilter: true,
      // initialState
      initialState: { pageSize: initialPageSize || 20, ...initialState },
      ...rest,
    },
    useGroupBy,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    if (onSelectedRowsChanged) onSelectedRowsChanged(selectedFlatRows);
  }, [onSelectedRowsChanged, selectedFlatRows]);

  /**
   * remote sorting only:
   */
  useEffect(() => {
    if (!disableSortBy && manualSortBy) {
      if (sortBy.length) {
        const sortObject = sortBy[0];
        onSortChange &&
          onSortChange({
            field: sortObject.id,
            order: sortObject.desc ? "desc" : "asc",
          });
      } else {
        onSortChange && onSortChange(undefined);
      }
    }
  }, [sortBy, sort?.remote, onSortChange, disableSortBy, manualSortBy]);

  // necessary to reset page when changing filters
  useEffect(() => {
    setGlobalFilter(filters);
  }, [setGlobalFilter, filters]);

  /**
   * remote pagination only:
   */
  useEffect(() => {
    if (remotePagination) {
      onPageChange &&
        onPageChange(pageIndex + 1, pageSize as TablePageLimitType);
    }
  }, [remotePagination, pageIndex, pageSize, onPageChange]);

  const TABLE_MULTIPLIER = 1;

  return (
    <Box position="relative">
      <Box
        sx={{ overflow: "scroll", paddingBottom: 0 }}
        className="scrollbar-hidden"
      >
        <Stack
          {...getTableProps()}
          sx={{ minWidth: "100%" }}
          flexDirection="column"
          width="max-content"
        >
          <Stack
            sx={{
              backgroundColor: "#fff",
              borderBottom: "1px solid #E9EBEB",
              // position: "sticky",
              // top: 0,
              // zIndex: 10,
            }}
            // width="calc(100% + 50px)"
          >
            {headerGroups.map((headerGroup) => (
              <Stack {...headerGroup.getHeaderGroupProps()} direction="row">
                {headerGroup.headers.map((column, index) => {
                  const isFirst = index === 0;
                  return (
                    <Stack
                      sx={{
                        height: 45,
                        "&:hover": { svg: { color: "primary.main" } },
                      }}
                      {...column.getHeaderProps({
                        ...column.getSortByToggleProps(),
                        style: {
                          fontWeight: 700,
                          textAlign: column.align,
                          minWidth: column.minWidth ?? "auto",
                          width: column.width ?? "auto",
                          cursor: "pointer",
                          color: theme.palette.grey[600],
                          fontSize: 14,
                          borderTop: "1px solid #E9EBEB",
                          borderRight: "1px solid #E9EBEB",
                          borderLeft: isFirst ? "1px solid #E9EBEB" : "none",
                          position: isFirst ? "sticky" : "static",
                          left: 0,
                          background: "#fff",
                        },
                      })}
                      paddingLeft={1}
                      direction="row"
                      alignItems="center"
                      justifyContent={isFirst ? "flex-start" : "center"}
                    >
                      {column.render("Header")}
                      {column.canSort && (
                        <Box
                          component="span"
                          sx={{ mx: 0.5, fontSize: "1.3em" }}
                        >
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <KeyboardArrowDown fontSize="inherit" />
                            ) : (
                              <KeyboardArrowUp fontSize="inherit" />
                            )
                          ) : (
                            <Sort fontSize="inherit" />
                          )}
                        </Box>
                      )}
                    </Stack>
                  );
                })}
              </Stack>
            ))}
          </Stack>

          {/** Status: loading -> displays skeleton */}
          {status === "loading" && (
            <TableBodyLoading
              rows={initialPageSize || 20}
              headerGroups={headerGroups}
            />
          )}

          {/**
           * When status is not used, it will just display rows
           * Status: success -> displays rows
           */}
          {(status === "success" || status === undefined) && (
            <Stack {...getTableBodyProps()} direction="column">
              {page.map((row) => {
                prepareRow(row);

                return (
                  <Stack
                    sx={{
                      height: 50 * TABLE_MULTIPLIER,
                      borderBottom: "1px solid #E9EBEB",

                      transition: "background-color 150ms ease-in-out",
                      "&:hover": {
                        bgcolor: "grey.100",
                      },
                      "&:hover > [role=cell]": {
                        bgcolor: "grey.100",
                      },
                    }}
                    {...row.getRowProps(getRowProps ? getRowProps(row) : {})}
                    direction="row"
                    alignItems="center"
                  >
                    {row.cells.map((cell, ci) => {
                      const isFirst = ci === 0;
                      const isLast = ci === row.cells.length - 1;

                      return (
                        <Stack
                          {...cell.getCellProps({
                            style: {
                              color: "#252525",
                              textAlign: cell.column.align,
                              minWidth: cell.column.minWidth ?? "auto",
                              width: cell.column.width ?? "auto",
                              fontSize: 14,
                              height: "100%",
                              borderRight: "1px solid #E9EBEB",
                              borderLeft: isFirst
                                ? "1px solid #E9EBEB"
                                : "none",
                              position: isFirst ? "sticky" : "static",
                              left: 0,
                              zIndex: isFirst ? 1 : 0,

                              transition: "background-color 150ms ease-in-out",
                            },
                          })}
                          bgcolor={"#fff"}
                          justifyContent="center"
                          pl={cell.column.padding ?? 1}
                          p={cell.column.padding}
                          mr={isLast ? 20 : 0}
                        >
                          {row.depth === 1
                            ? cell.render("Cell")
                            : cell.render("Aggregated")}
                        </Stack>
                      );
                    })}
                  </Stack>
                );
              })}
            </Stack>
          )}
        </Stack>
      </Box>

      {/* Additional Styling Row */}
      <Stack>
        <Stack
          sx={{
            height: 50 * TABLE_MULTIPLIER,
            paddingLeft: headerGroups?.[0]?.headers?.[0].width
              ? `${(headerGroups[0].headers[0].width as number) - 1}px`
              : 0,
          }}
          direction="row"
          alignItems="center"
        >
          <Stack
            sx={{
              width: "100vw",
              height: "100%",
              zIndex: 1,
              borderBottom: "1px solid #E9EBEB",
              borderLeft: "1px solid #E9EBEB",
            }}
          ></Stack>
        </Stack>
      </Stack>
      {/* Additional Styling Row */}

      {/**
       * Status: success and empty data -> displays empty message
       */}
      {status === "success" &&
        data.length === 0 &&
        ((emptyMessage && <>{emptyMessage}</>) || <TableEmptyMessage />)}

      {/**
       * Status: error -> displays error message
       */}
      {status === "error" && <TableErrorMessage />}
      {pageCount > 1 && (
        <TablePagination
          gotoPage={gotoPage}
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      )}

      {/* <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          background: "linear-gradient(90deg, transparent, #fff)",
          pointerEvents: "none",
        }}
        height="100%"
        width={{ xs: 150, md: 250, lg: 500 }}
      ></Box> */}
    </Box>
  );
};

export default Table;
