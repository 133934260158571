import { ApexOptions } from "apexcharts";
import React from "react";

import Chart from "components/Chart";

const WeeklyOverviewChart = () => {
  const chartOptions: ApexOptions = {
    chart: {
      stacked: true,
    },
    xaxis: {
      //   categories: profitsData?.data?.keys ?? [],
      categories: [
        "Mon, Jul 10",
        "Tue, Jul 11",
        "Wed, Jul 12",
        "Thu, Jul 13",
        "Fri, Jul 14",
        "Sat, Jul 15",
        "Sun, Jul 16",
      ],
      labels: {
        formatter: function (value) {
          return value;
          //   return dayjs(value).format("MMM");
        },
      },
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (value) {
        return `${value}`;
      },
      offsetY: -30,
      style: {
        fontSize: "14px",
        fontWeight: "normal",
        colors: ["#676b6c"],
      },
    },
  };

  const chartSeries: ApexOptions["series"] = [
    {
      //   name: intl.formatMessage({ id: "GLOBAL.COSTS" }),
      //   data: costsData?.data?.values ?? [],
      name: "Total Time",
      data: [50, 30, 20, 40, 60, 70, 0],
    },
  ];

  return (
    <Chart
      type="bar"
      height={500}
      options={chartOptions}
      series={chartSeries}
      //   status={}
    />
  );
};

export default WeeklyOverviewChart;
