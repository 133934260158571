import { Box, Divider, Tab, Tabs, Typography } from "@mui/material";
import React, { FC } from "react";
import { useHistory, useLocation } from "react-router-dom";

import HeaderImage from "assets/images/header-image.png";

interface IHeader {
  label: string;
  tabs?: { label: string; path: string }[];
}

const Header: FC<IHeader> = ({ label, tabs }) => {
  const { pathname } = useLocation();
  const history = useHistory();

  return (
    <Box
      sx={{
        position: Boolean(tabs?.length) ? "sticky" : "static",
        top: "-80px", // Height of Tabs
        zIndex: 10,
        backgroundColor: "#fff",
      }}
    >
      <Box
        sx={{
          height: 130,

          background: "no-repeat center center / cover",
          backgroundImage: `url('${HeaderImage}')`,
        }}
      >
        <Typography variant="h1" sx={{ pt: 3, pl: 4 }}>
          {label}
        </Typography>

        {tabs?.length && (
          <Tabs
            value={pathname}
            variant="scrollable"
            scrollButtons={false}
            sx={{ mt: 2.6, pl: 3.5 }}
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.path}
                label={tab.label}
                value={tab.path}
                onClick={() => history.push(tab.path)}
              ></Tab>
            ))}
          </Tabs>
        )}
      </Box>
      <Divider />
    </Box>
  );
};

export default Header;
