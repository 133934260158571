import { Box, Stack } from "@mui/material";

const statusColor = {
  "on-track": "#00c875",
  "at-risk": "#fdab3d",
  "off-track": "#e2435b",
};

export const ProjectStatusFormatter = ({
  status,
}: {
  status: "on-track" | "at-risk" | "off-track";
}) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        height: "100%",
        textTransform: "capitalize",
      }}
    >
      <Box
        sx={{
          height: "12px",
          width: "12px",
          mr: 1.5,
          ml: 2.5,
          borderRadius: "50%",
          backgroundColor: statusColor[status],
        }}
      ></Box>

      <Box>{status.split("-").join(" ")}</Box>
    </Stack>
  );
};
