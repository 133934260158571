export type TrackTimerType = {
  enabled: boolean;
  start: Date | null;
};

const DEFAULT_TRACK_TIMER: TrackTimerType = {
  enabled: false,
  start: null,
};

export const getTrackTimer = () =>
  localStorage.getItem("track-timer")
    ? (JSON.parse(
        localStorage.getItem("track-timer") as string
      ) as TrackTimerType)
    : DEFAULT_TRACK_TIMER;

export const startTrackTimer = () => {
  const trackTimeStorage: TrackTimerType = {
    enabled: true,
    start: new Date(),
  };
  localStorage.setItem("track-timer", JSON.stringify(trackTimeStorage));

  return trackTimeStorage;
};
