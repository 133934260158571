import { Box, Button, Divider, Stack } from "@mui/material";
import { Form as FormikForm, FormikProvider, useFormik } from "formik";
import React from "react";
import * as yup from "yup";

import FormikSwitch from "components/forms/FormikCheckbox";

const NotificationsForm = () => {
  const handleSubmit = async (values: any) => {
    console.log(values);
  };

  const formik = useFormik({
    initialValues: {
      newFeatures: false,
      monthlyTimeReports: false,
      overdueProjectsTasks: false,
    },
    validationSchema: yup.object({
      newFeatures: yup.boolean().required(),
      monthlyTimeReports: yup.boolean().required(),
      overdueProjectsTasks: yup.boolean().required(),
    }),
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <FormikProvider value={formik}>
      <FormikForm>
        <Box p={2}>
          <FormikSwitch
            name="newFeatures"
            label="New compaVion feature annoucments"
          />
          <FormikSwitch
            name="monthlyTimeReports"
            label="Monthly time reports"
          />
          <FormikSwitch
            name="overdueProjectsTasks"
            label="Overdue projects and tasks"
          />
        </Box>
        <Divider />
        <Stack direction="row" justifyContent="end" py={1} px={2}>
          <Button type="submit" variant="contained">
            Save
          </Button>
        </Stack>
      </FormikForm>
    </FormikProvider>
  );
};

export default NotificationsForm;
