import React from "react";
import { Route, Switch } from "react-router-dom";

import Header from "components/pages/Header/Header";

import ClientsList from "./clients-list";
import ClientsTimeline from "./clients-timeline";
import ClientsWorkflows from "./clients-workflows";

const ClientsPage = () => {
  return (
    <>
      <Header
        label="Clients"
        tabs={[
          {
            label: "List",
            path: "/clients",
          },
          {
            label: "Timeline",
            path: "/clients/timeline",
          },
          {
            label: "Workflows",
            path: "/clients/workflows",
          },
        ]}
      />

      <Switch>
        <Route exact path="/clients" component={ClientsList} />
        <Route exact path="/clients/timeline" component={ClientsTimeline} />
        <Route exact path="/clients/workflows" component={ClientsWorkflows} />
      </Switch>
    </>
  );
};

export default ClientsPage;
