import { createBrowserHistory } from "history";
import React from "react";
import { Redirect, Switch } from "react-router-dom";

import AuthRoute from "components/routes/AuthRoute";
import ClientsPage from "containers/clients";
import DashboardPage from "containers/dashboard";
import DocumentationPage from "containers/documentation";
import EmployeesPage from "containers/employees";
import FAQPage from "containers/faq";
import FinancesPage from "containers/finances";
import InvoicesPage from "containers/invoices";
import LoginPage from "containers/login";
import ProfilePage from "containers/profile";
import ProjectsPage from "containers/projects";
import ProposalsPage from "containers/proposals";
import RoadmapPage from "containers/roadmap";
import TimesheetPage from "containers/timesheet";

import PrivateRoute from "./components/routes/PrivateRoute";
import Providers from "./Providers";

export const history = createBrowserHistory();

// 
export default function App() {
  return (
    <Providers history={history}>
      <Switch>
        <AuthRoute exact component={LoginPage} path="/login" />

        {/* Operational Pages */}
        <PrivateRoute
          exact
          path="/dashboard"
          component={DashboardPage}
          // requiredPermissions={[]}
        />
        <PrivateRoute
          path="/clients"
          component={ClientsPage}
          // requiredPermissions={[]}
        />
        <PrivateRoute
          path="/projects"
          component={ProjectsPage}
          // requiredPermissions={[]}
        />
        <PrivateRoute
          path="/documentation"
          component={DocumentationPage}
          // requiredPermissions={[]}
        />

        {/* Accounting Pages */}
        <PrivateRoute
          path="/invoices"
          component={InvoicesPage}
          // requiredPermissions={[]}
        />
        <PrivateRoute
          path="/proposals"
          component={ProposalsPage}
          // requiredPermissions={[]}
        />
        <PrivateRoute
          path="/finances"
          component={FinancesPage}
          // requiredPermissions={[]}
        />

        {/* Management Pages */}
        <PrivateRoute
          path="/employees"
          component={EmployeesPage}
          // requiredPermissions={[]}
        />
        <PrivateRoute
          path="/timesheet"
          component={TimesheetPage}
          // requiredPermissions={[]}
        />

        {/* Help Pages */}
        <PrivateRoute
          path="/profile"
          component={ProfilePage}
          // requiredPermissions={[]}
        />
        <PrivateRoute
          path="/roadmap"
          component={RoadmapPage}
          // requiredPermissions={[]}
        />

        <PrivateRoute
          path="/faq"
          component={FAQPage}
          // requiredPermissions={[]}
        />

        <Redirect to="/dashboard" />
      </Switch>
    </Providers>
  );
}
