import { Box } from "@mui/material";
import React from "react";

import ClientsTimelineActions from "./ClientsTimelineActions";
import ClientsVisTimeline from "./ClientsVisTimeline/ClientsVisTimeline";

const ClientsTimeline = () => {
  return (
    <Box sx={{ py: 4, px: 3 }}>
      <Box pb={1} mb={2} bgcolor="#fff">
        <ClientsTimelineActions />
      </Box>

      <ClientsVisTimeline />
    </Box>
  );
};

export default ClientsTimeline;
