import {
  Pause as PauseIcon,
  PlayArrow as PlayArrowIcon,
} from "@mui/icons-material";
import { Box, ButtonBase, Stack } from "@mui/material";
import PopupState, { bindTrigger } from "material-ui-popup-state";
import React, { useEffect, useRef, useState } from "react";

import dayjs from "utils/dayjs";

import TrackTimePopover from "./TrackTimePopover";
import { TrackTimerType, getTrackTimer } from "./trackTimeStorage";

const Timer = () => {
  const containerRef = useRef<any>(null);

  const [duration, setDuration] = useState<string | null>();
  const trackTimer: TrackTimerType = getTrackTimer();

  useEffect(() => {
    setDuration(dayjs.prototype.getDuration(trackTimer.start, new Date()));
    const updateInterval = setInterval(() => {
      setDuration(dayjs.prototype.getDuration(trackTimer.start, new Date()));
    }, 1000);

    return () => {
      clearInterval(updateInterval);
    };
  }, [trackTimer.start]);

  return (
    <PopupState variant="popover" popupId="track-time-popover">
      {(popupState) => (
        <>
          <Stack
            ref={containerRef}
            sx={{
              px: 2,
              transition: "background-color 150ms ease-in-out",
              whiteSpace: "nowrap",
              ":hover": {
                backgroundColor: (theme) => theme.palette.grey[200],
              },
            }}
            alignItems="center"
            justifyContent="center"
            fontWeight={500}
            component={ButtonBase}
            height="100%"
            direction="row"
            {...bindTrigger(popupState)}
          >
            <Box color="#212121" mr={1.5} textAlign="right">
              {trackTimer.enabled ? duration ?? "00:00:00" : "Track Time"}
            </Box>

            <Box sx={{ zIndex: 1 }}>
              <Stack
                sx={{
                  position: "relative",
                  bgcolor: (theme) =>
                    trackTimer.enabled ? "#D35438" : theme.palette.primary.main,
                  mx: 0.5,

                  ":before": {
                    content: "''",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                    bgcolor: trackTimer.enabled ? "#FFD3AB" : "#ABCBFF",
                    borderRadius: "50%",
                    width: "36px",
                    height: "36px",
                    zIndex: -1,
                    animation: trackTimer.enabled
                      ? `pulse 2000ms linear infinite`
                      : "none",
                  },
                }}
                height="28px"
                width="28px"
                borderRadius="50%"
                alignItems="center"
                justifyContent="center"
              >
                {trackTimer.enabled ? (
                  <PauseIcon sx={{ color: "#fff", fontSize: 20 }} />
                ) : (
                  <PlayArrowIcon sx={{ color: "#fff", fontSize: 20 }} />
                )}
              </Stack>
            </Box>
          </Stack>

          <TrackTimePopover
            anchorEl={containerRef.current as Element}
            popupState={popupState}
          />
        </>
      )}
    </PopupState>
  );
};

export default Timer;
