import React from "react";
import { Route, Switch } from "react-router-dom";

import Header from "components/pages/Header/Header";

import InvoicesList from "./invoices-list";

const InvoicesPage = () => {
  return (
    <>
      <Header
        label="Invoices"
        tabs={[
          {
            label: "List",
            path: "/invoices",
          },
          {
            label: "Templates",
            path: "/invoices/templates",
          },
        ]}
      />

      <Switch>
        <Route exact path="/invoices" component={InvoicesList} />
      </Switch>
    </>
  );
};

export default InvoicesPage;
