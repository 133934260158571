import { Box, Stack, Typography } from "@mui/material";
import React from "react";

import { ReactComponent as ArrowRightIcon } from "assets/icons/arrow-right-icon.svg";
import { ReactComponent as BookIcon } from "assets/icons/book-icon.svg";
import { ReactComponent as InfoIcon } from "assets/icons/info-icon.svg";

const faqCategories = [
  {
    icon: InfoIcon,
    title: "Getting Started Guide",
    description:
      "Wondering where to start? Get to know the basics to put your company on autopilot!",
    articles: 7,
  },
  {
    icon: BookIcon,
    title: "General Questions & Overview",
    description:
      "Get all your general questions about using compaVion answered.",
    articles: 47,
  },
];

const FAQCategories = () => {
  return (
    <Box sx={{ py: 4, px: 3 }}>
      <Stack direction="column" spacing={2} mb={5}>
        {faqCategories.map(({ icon: Icon, title, description, articles }) => (
          <Stack
            key={title}
            direction="row"
            sx={{
              py: 2.5,
              px: 4,
              border: "1px solid #E9EBEB",
              cursor: "pointer",
              position: "relative",

              "&:hover .open": {
                opacity: 1,
              },
            }}
            spacing={3}
          >
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                svg: {
                  stroke: (theme) => theme.palette.primary.main,
                  height: 60,
                  width: 60,
                },
              }}
            >
              <Icon />
            </Stack>
            <Box>
              <Typography fontWeight="bold" fontSize={18} mb={0.2}>
                {title}
              </Typography>
              <Typography mb={0.5}>{description}</Typography>
              <Typography fontSize={14} color="#828282">
                {articles} articles
              </Typography>
            </Box>

            <Box
              className="open"
              sx={{
                opacity: 0,
                position: "absolute",
                right: 30,
                top: "50%",
                transform: "translateY(-50%)",
                color: (theme) => theme.palette.primary.main,

                svg: {
                  height: 40,
                  width: 40,
                },

                transition: "opacity 150ms ease-in-out",
              }}
            >
              <ArrowRightIcon />
            </Box>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};

export default FAQCategories;
