import { Box } from "@mui/material";
import React from "react";

import EmployeesListActions from "./EmployeesListActions";
import EmployeesListTable from "./EmployeesListTable/EmployeesListTable";

const EmployeesList = () => {
  return (
    <Box sx={{ py: 4, pl: 3 }}>
      <Box pb={1} pr={3} mb={2} bgcolor="#fff">
        <EmployeesListActions />
      </Box>
      <EmployeesListTable />
    </Box>
  );
};

export default EmployeesList;
