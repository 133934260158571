import { Box, Divider, Theme, useMediaQuery } from "@mui/material";
import React, { useState } from "react";

import RightClickPopup from "components/providers/RightClickPopup";
import ScrollTopProvider from "components/providers/ScrollTopProvider";
import { WithChildren } from "utils/types";

import Alert from "./Alert/Alert";
import Footer from "./Footer";
import SidebarDrawer from "./SidebarDrawer";
import Topbar from "./Topbar";

interface DefaultLayoutProps {}

const DefaultLayout = ({ children }: WithChildren<DefaultLayoutProps>) => {
  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("md")
  );

  const previousSidebarState: boolean | null =
    localStorage.getItem("sidebar-state") &&
    JSON.parse(localStorage.getItem("sidebar-state") as string);

  const [isDesktopDrawerShrinked, setIsDesktopDrawerShrinked] = useState(
    previousSidebarState ?? false
  );
  const toggleDesktopDrawer = () =>
    setIsDesktopDrawerShrinked((prev) => {
      const newSidebarState = !prev;
      localStorage.setItem("sidebar-state", JSON.stringify(newSidebarState));
      return !prev;
    });

  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);
  const toggleMobileDrawer = () => setIsMobileDrawerOpen((prev) => !prev);

  return (
    <>
      <ScrollTopProvider>
        <Box
          display="grid"
          gridTemplateAreas="
        'sidebar topbar' 
        'sidebar content'
      "
          gridTemplateColumns="min-content 1fr"
          gridTemplateRows="min-content 1fr"
          height="100vh"
          width="100vw"
        >
          <Box gridArea="sidebar">
            <SidebarDrawer
              isMobile={isMobile}
              isMobileDrawerOpen={isMobileDrawerOpen}
              toggleMobileDrawer={toggleMobileDrawer}
              isDesktopDrawerShrinked={isDesktopDrawerShrinked}
              toggleDesktopDrawer={toggleDesktopDrawer}
            />
          </Box>

          <Box gridArea="topbar">
            <Topbar
              isMobile={isMobile}
              toggleMobileDrawer={toggleMobileDrawer}
            />
          </Box>

          <Box
            gridArea="content"
            minHeight="100%"
            height="100%"
            display="flex"
            flexDirection="column"
            sx={{
              overflow: "auto",
              paddingTop: isMobile ? "64px" : 0,
            }}
            className="content"
          >
            <Alert />
            <Divider />
            <Box
              component="main"
              sx={{
                position: "relative",
                flexGrow: 1,
                overflow: "scroll",
                // height: "50%",
                // p: isMobile ? 2 : 3,
                // py: isMobile ? 4 : 2,
                backgroundColor: (t) => t.palette.background.default,
              }}
            >
              {children}
            </Box>

            <Divider />
            <Footer />
          </Box>
        </Box>
      </ScrollTopProvider>
      <RightClickPopup />
    </>
  );
};

export default DefaultLayout;
