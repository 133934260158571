import { LoadingButton } from "@mui/lab";
import { Link, Stack, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { Form as FormikForm, FormikProvider, useFormik } from "formik";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Link as RouterLink, useHistory } from "react-router-dom";
import * as yup from "yup";

import { postLogin as postLoginAPI } from "api/auth";
import { LoginPayloadType } from "api/auth/types";
import { axiosInstance } from "api/request";
import FormikTextField from "components/forms/FormikTextField";

const LoginForm = () => {
  const history = useHistory();

  const { mutateAsync: login, isLoading } = useMutation(async (values: any) => {
    const { data: loginData } = await postLoginAPI(values);

    localStorage.setItem("JWToken", loginData?.token);
    axiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${loginData?.token}`;
  });

  const handleSubmit = async (values: LoginPayloadType) => {
    await login(values, {
      onSuccess: () => history.push("/dashboard"),
    });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object({
      email: yup.string().required(),
      password: yup.string().required(),
    }),
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <FormikProvider value={formik}>
      <FormikForm>
        <FormikTextField
          name="email"
          sx={{ mb: 1 }}
          label={<FormattedMessage id="LOGIN.FORM.EMAIL" />}
        />
        <FormikTextField
          name="password"
          type="password"
          sx={{ mb: 2 }}
          label={<FormattedMessage id="LOGIN.FORM.PASSWORD" />}
        />

        <Stack direction="row" justifyContent="flex-end" sx={{ mb: 2 }}>
          <Typography variant="body2">
            <Link component={RouterLink} to={`/forgot-password`}>
              <FormattedMessage id="LOGIN.FORM.FORGOT_PASSWORD" />
            </Link>
          </Typography>
        </Stack>

        <LoadingButton
          type="submit"
          variant="contained"
          fullWidth
          loading={isLoading}
          sx={{ py: 1 }}
        >
          <FormattedMessage id="LOGIN.FORM.BUTTON" />
        </LoadingButton>
      </FormikForm>
    </FormikProvider>
  );
};

export default LoginForm;
