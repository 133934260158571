import {
  Box,
  ButtonBase,
  Divider,
  Popover,
  Stack,
  useTheme,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
//@ts-expect-error
import { useScreenshot } from "use-react-screenshot";

import { ReactComponent as AlertIcon } from "assets/icons/alert-icon.svg";
import { ReactComponent as ArrowLeftIcon } from "assets/icons/arrow-left-icon.svg";
import { ReactComponent as MaximizeIcon } from "assets/icons/maximize-icon.svg";
import { ReactComponent as MinimizeIcon } from "assets/icons/minimize-icon.svg";
import { ReactComponent as RefetchIcon } from "assets/icons/refetch-icon.svg";
import { ReactComponent as ReloadIcon } from "assets/icons/reload-icon.svg";
import { ReactComponent as ScreenshotIcon } from "assets/icons/screenshot-icon.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings-icon.svg";

const RightClickPopup = () => {
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();
  const queryClient = useQueryClient();

  const [image, takeScreenshot] = useScreenshot();

  const anchorEl = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [position, setPosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    const customContextMenu = (event: MouseEvent) => {
      event.preventDefault();
      takeScreenshot(document.body);

      const { clientX, clientY } = event;
      setPosition({ x: clientX, y: clientY });
      setOpen(true);
    };

    document.addEventListener("contextmenu", customContextMenu);
    return () => {
      document.removeEventListener("contextmenu", customContextMenu);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const contextMenuList = [
    {
      display: true,
      key: "back",
      icon: <ArrowLeftIcon />,
      text: "Back",

      onClick: () => {
        history.goBack();
      },
      disabled: !location.key,
    },
    {
      display: true,
      key: "clear-cache",
      icon: <RefetchIcon />,
      text: "Clear Cache",

      onClick: () => {
        queryClient.removeQueries([]);
      },
    },
    {
      display: true,
      key: "reload",
      icon: <ReloadIcon />,
      text: "Reload",
      onClick: () => {
        window.location.href = window.location.pathname;
      },
    },
    {
      display: !Boolean(!window.screenTop && !window.screenY),
      key: "fullscreen",
      icon: <MaximizeIcon />,
      text: "Fullscreen",

      onClick: () => {
        const body = document.body;

        if (body.requestFullscreen) {
          body.requestFullscreen();
          //@ts-expect-error
        } else if (body.webkitRequestFullscreen) {
          //@ts-expect-error
          body.webkitRequestFullscreen();
          //@ts-expect-error
        } else if (body.msRequestFullscreen) {
          //@ts-expect-error
          body.msRequestFullscreen();
        }
      },
    },
    {
      display: Boolean(!window.screenTop && !window.screenY),
      key: "exit-fullscreen",
      icon: <MinimizeIcon />,
      text: "Exit Fullscreen",

      onClick: () => {
        if (document.exitFullscreen) {
          document.exitFullscreen();
          //@ts-expect-error
        } else if (document.webkitExitFullscreen) {
          //@ts-expect-error
          document.webkitExitFullscreen();
          //@ts-expect-error
        } else if (document.msExitFullscreen) {
          //@ts-expect-error
          document.msExitFullscreen();
        }
      },
    },
    {
      display: true,

      key: "screenshot",
      icon: <ScreenshotIcon />,
      text: "Screenshot",

      onClick: () => {
        const downloadLink = document.createElement("a");
        downloadLink.href = image;
        downloadLink.download = `CompaVion-Screenshot-${Date.now()}.png`;
        downloadLink.click();
      },
      disabled: !Boolean(image),

      divider: true,
    },

    {
      display: true,
      key: "report-issue",
      icon: <AlertIcon />,
      text: "Report Issue",

      color: theme.palette.warning.main,

      onClick: () => {
        console.log(image);
      },
    },
    {
      display: true,
      key: "settings",
      icon: <SettingsIcon />,
      text: "Settings",

      onClick: () => {
        history.push("/profile/settings");
      },
    },
  ];
  return (
    <>
      <div
        style={{
          position: "absolute",
          width: "1px",
          height: "1px",
          backgroundColor: "black",
          left: `${position.x}px`,
          top: `${position.y}px`,
        }}
        ref={anchorEl}
      ></div>

      {/* Popover */}
      <Popover
        open={open}
        anchorEl={anchorEl.current}
        onClose={() => setOpen(false)}
      >
        <Stack
          direction="column"
          sx={{ width: "180px", fontSize: 14, py: 0.5 }}
        >
          {contextMenuList
            .filter(({ display }) => Boolean(display))
            .map(({ key, icon, text, onClick, color, divider, disabled }) => (
              <React.Fragment key={key}>
                <Stack
                  component={ButtonBase}
                  py={1}
                  px={1}
                  justifyContent="flex-start"
                  fontWeight={500}
                  disabled={disabled}
                  onClick={() => {
                    if (onClick) {
                      onClick();
                    }

                    setOpen(false);
                  }}
                  sx={{
                    color: (theme) => color || theme.palette.grey[700],
                    opacity: disabled ? 0.5 : 1,
                    svg: {
                      width: 15,
                      height: 15,
                    },

                    transition: "background-color 150ms ease-in-out",
                    ":hover": {
                      backgroundColor: (theme) => theme.palette.grey[200],
                    },
                  }}
                  direction="row"
                >
                  <Box sx={{ width: 20 }} mr={1.5}>
                    {icon}
                  </Box>
                  <Box>{text}</Box>
                </Stack>

                {divider && <Divider sx={{ my: 0.5 }} />}
              </React.Fragment>
            ))}
        </Stack>
      </Popover>
    </>
  );
};

export default RightClickPopup;
