import { Add as AddIcon } from "@mui/icons-material";
import { ButtonBase, Stack } from "@mui/material";
import PopupState, { bindTrigger } from "material-ui-popup-state";
import React, { FC, useRef } from "react";

import AddButtonPopover from "./AddButtonPopover";

interface IAddButton {
  label: string;
}

const AddButton: FC<IAddButton> = ({ label }) => {
  const containerRef = useRef<any>(null);

  return (
    <PopupState variant="popover" popupId="add-button-popover">
      {(popupState) => (
        <>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{
              bgcolor: (theme) => theme.palette.primary.main,
              color: "#fff",
              borderRadius: 1,
              height: 34,
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
            ref={containerRef}
          >
            <Stack
              sx={{
                fontSize: 14,
                height: "100%",
                transition: "background-color 150ms ease-in-out",
                "&:hover": {
                  bgcolor: (theme) => theme.palette.primary.dark,
                },
              }}
              px={3}
              fontWeight={500}
              component={ButtonBase}
            >
              {label}
            </Stack>

            <Stack
              sx={{
                width: "35px",
                borderLeft: "1px solid #fff",
                height: "100%",
                transition: "background-color 150ms ease-in-out",
                "&:hover": {
                  bgcolor: (theme) => theme.palette.primary.dark,
                },
              }}
              alignItems="center"
              justifyContent="center"
              component={ButtonBase}
              {...bindTrigger(popupState)}
            >
              <AddIcon sx={{ fontSize: 22 }} />
            </Stack>
          </Stack>

          <AddButtonPopover
            anchorEl={containerRef.current as Element}
            popupState={popupState}
          />
        </>
      )}
    </PopupState>
  );
};

export default AddButton;
