import { Box, Stack, Typography } from "@mui/material";
import React from "react";

import Header from "components/pages/Header/Header";

import WeeklyOverviewChart from "./components/WeeklyOverviewChart";

const DashboardPage = () => {
  return (
    <>
      <Header label="Dashboard" />

      <Box sx={{ py: 4, px: 3 }}>
        <Typography variant="h4" mb={2}>
          Weekly Overview
        </Typography>
        <Box sx={{ border: "1px solid #E9EBEB" }} mb={5}>
          <Box
            sx={{ borderBottom: "1px solid #E9EBEB" }}
            height={48}
            mb={5}
          ></Box>
          <WeeklyOverviewChart />
        </Box>

        <Typography variant="h4" mb={2}>
          My Tasks
        </Typography>
        <Box sx={{ border: "1px solid #E9EBEB" }} mb={5}>
          {/* No Tasks */}
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            p={3}
          >
            <Typography variant="body2">
              You don't have any active tasks.
            </Typography>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default DashboardPage;
