import React from "react";
import { Route, Switch } from "react-router-dom";

import Header from "components/pages/Header/Header";

import ProjectsList from "./projects-list";
import ProjectsTimeline from "./projects-timeline";
import ProjectsWorkflows from "./projects-workflows";

const ProjectsPage = () => {
  return (
    <>
      <Header
        label="Projects"
        tabs={[
          {
            label: "List",
            path: "/projects",
          },
          {
            label: "Timeline",
            path: "/projects/timeline",
          },
          {
            label: "Workflows",
            path: "/projects/workflows",
          },
        ]}
      />

      <Switch>
        <Route exact path="/projects" component={ProjectsList} />
        <Route exact path="/projects/timeline" component={ProjectsTimeline} />
        <Route exact path="/projects/workflows" component={ProjectsWorkflows} />
      </Switch>
    </>
  );
};

export default ProjectsPage;
