import { CustomContentProps } from "notistack";
import { forwardRef } from "react";

import SnackbarBaseComponent from "./SnackbarBaseComponent";

const SnackbarErrorComponent = forwardRef<HTMLDivElement, CustomContentProps>(
  ({ id, ...props }, ref) => {
    return (
      <SnackbarBaseComponent id={id} color="#e2435b" {...props} ref={ref} />
    );
  }
);

export default SnackbarErrorComponent;
