import {
  Pause as PauseIcon,
  PlayArrow as PlayArrowIcon,
} from "@mui/icons-material";
import { Box, Button, Divider, Popover, Stack } from "@mui/material";
import { bindPopover } from "material-ui-popup-state";
import { PopupState } from "material-ui-popup-state/core";
import React, { FC, useEffect, useState } from "react";

import dayjs from "utils/dayjs";

import TrackTimeForm from "./TrackTimeForm";
import { TrackTimerType, getTrackTimer } from "./trackTimeStorage";

interface ITrackTimePopover {
  anchorEl: Element;
  popupState: PopupState;
}

const TrackTimePopover: FC<ITrackTimePopover> = ({ anchorEl, popupState }) => {
  const [duration, setDuration] = useState<string | null>();
  const trackTimer: TrackTimerType = getTrackTimer();

  useEffect(() => {
    setDuration(dayjs.prototype.getDuration(trackTimer.start, new Date()));
    const updateInterval = setInterval(() => {
      setDuration(dayjs.prototype.getDuration(trackTimer.start, new Date()));
    }, 1000);

    return () => {
      clearInterval(updateInterval);
    };
  }, [trackTimer.start]);

  return (
    <Popover
      {...bindPopover(popupState)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      sx={{
        mt: "-3px",
      }}
    >
      <Box sx={{ width: 320 }}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ py: 2, pb: 3 }}
        >
          <Box fontSize={13} fontWeight={500}>
            START TIMER
          </Box>
          <Box mb={2} fontSize={33} fontWeight={500} lineHeight={1.2}>
            {trackTimer.enabled ? duration ?? "00:00:00" : "00:00:00"}
          </Box>
          {/* Button */}
          <Box sx={{ zIndex: 1, mb: 1, cursor: "pointer" }}>
            <Stack
              sx={{
                position: "relative",
                bgcolor: (theme) =>
                  trackTimer.enabled ? "#D35438" : theme.palette.primary.main,
                mx: 0.5,

                ":before": {
                  content: "''",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                  bgcolor: trackTimer.enabled ? "#FFD3AB" : "#ABCBFF",
                  borderRadius: "50%",
                  width: "80px",
                  height: "80px",
                  zIndex: -1,
                  animation: trackTimer.enabled
                    ? `pulse 2000ms linear infinite`
                    : "none",
                },
              }}
              height="64px"
              width="64px"
              borderRadius="50%"
              alignItems="center"
              justifyContent="center"
            >
              {trackTimer.enabled ? (
                <PauseIcon sx={{ color: "#fff", fontSize: 48 }} />
              ) : (
                <PlayArrowIcon sx={{ color: "#fff", fontSize: 48 }} />
              )}
            </Stack>
          </Box>
        </Stack>

        <Divider sx={{ mx: 1 }} />

        <Stack sx={{ my: 2, mx: 2 }}>
          <TrackTimeForm />

          <Stack direction="row" spacing={1} sx={{ mt: 2, width: "100%" }}>
            <Button
              variant="contained"
              fullWidth
              sx={{ height: 38, fontSize: 15 }}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="error"
              fullWidth
              sx={{ height: 38, fontSize: 15 }}
            >
              Discard
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Popover>
  );
};

export default TrackTimePopover;
