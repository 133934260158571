import { CurrentUserType, LoginPayloadType, LoginType } from "./types";
import request, { APICall } from "../request";

export const getCurrentUser = (): APICall<{ data: CurrentUserType }> =>
  request({
    url: `/auth/me`,
    method: "GET",
  });

export const postLogin = (body: LoginPayloadType): APICall<LoginType> =>
  request({
    data: body,
    url: `/auth/login`,
    method: "POST",
  });
