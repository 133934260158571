import { Box, LinearProgress, Stack } from "@mui/material";
export const EmployeeWorkloadFormatter = ({
  workload,
}: {
  workload: number;
}) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={{ width: "100%", height: "100%" }}
      spacing={1}
    >
      <Box sx={{ width: "100%", maxWidth: "100px" }}>
        <LinearProgress
          color="success"
          //   color="primary"
          variant="determinate"
          value={workload}
          sx={{
            height: 10,
            bgcolor: "#e9e9e9",
            borderRadius: "2px",
          }}
        />
      </Box>
      <Box sx={{ width: 25, fontSize: 12, pb: "2px" }}>
        {/* {workload}% */}
        8/40
      </Box>
    </Stack>
  );
};
