import { Avatar, Button, Stack } from "@mui/material";
import PopupState, { bindTrigger } from "material-ui-popup-state";
import React, { useRef } from "react";

import { useUserStore } from "components/stores/UserStore";

import ProfilePopover from "./ProfilePopover";

const Profile = () => {
  const containerRef = useRef<any>(null);

  const [user] = useUserStore((s) => [s.user]);

  const userName = user?.name;

  const userInitials =
    userName
      ?.split(" ")
      .map((chunk: string) => chunk.charAt(0))
      .join("") || "";

  return (
    <PopupState variant="popover" popupId="track-time-popover">
      {(popupState) => (
        <>
          <Button
            ref={containerRef}
            sx={{
              height: "100%",
              boxShadow: "none",
              px: 3,
              borderRadius: 0,
              color: (theme) => theme.palette.grey[800],
              transition: "background-color 150ms ease-in-out",
              ":hover": {
                backgroundColor: (theme) => theme.palette.grey[200],
              },
            }}
            {...bindTrigger(popupState)}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <Avatar
                sx={{
                  backgroundColor: (theme) => theme.palette.primary.main,
                  fontSize: Math.min(35, 40 / userInitials.length),
                }}
                variant="rounded"
              >
                {userInitials.toUpperCase()}
              </Avatar>
            </Stack>
          </Button>

          <ProfilePopover
            anchorEl={containerRef.current as Element}
            popupState={popupState}
          />
        </>
      )}
    </PopupState>
  );
};

export default Profile;
