import React from "react";
import { Route, Switch } from "react-router-dom";

import Header from "components/pages/Header/Header";

import EmployeesList from "./employees-list";
import EmployeesTimeline from "./employees-timeline";

const EmployeesPage = () => {
  return (
    <>
      <Header
        label="Employees"
        tabs={[
          {
            label: "List",
            path: "/employees",
          },
          {
            label: "Timeline",
            path: "/employees/timeline",
          },
        ]}
      />

      <Switch>
        <Route exact path="/employees" component={EmployeesList} />
        <Route exact path="/employees/timeline" component={EmployeesTimeline} />
      </Switch>
    </>
  );
};

export default EmployeesPage;
