import React from "react";
import { Route, Switch } from "react-router-dom";

import Header from "components/pages/Header/Header";

import ProposalsList from "./proposals-list";

const ProposalsPage = () => {
  return (
    <>
      <Header
        label="Proposals"
        tabs={[
          {
            label: "List",
            path: "/proposals",
          },
          {
            label: "Templates",
            path: "/proposals/templates",
          },
        ]}
      />

      <Switch>
        <Route exact path="/proposals" component={ProposalsList} />
      </Switch>
    </>
  );
};

export default ProposalsPage;
