import { Skeleton, Stack } from "@mui/material";
import { HeaderGroup } from "react-table";

interface Props<T extends object> {
  rows: number;
  headerGroups: HeaderGroup<T>[];
}

const TableBodyLoading = <T extends object>({
  rows,
  headerGroups,
}: Props<T>) => {
  return (
    <Stack>
      {Array.from({ length: rows }).map((_, ri) => {
        return (
          <Stack
            key={ri}
            sx={{ height: 50, borderBottom: "1px solid #E9EBEB" }}
            direction="row"
          >
            {headerGroups.map((headerGroup) =>
              headerGroup.headers.map((_, ci) => {
                const isFirst = ci === 0;

                return (
                  <Stack
                    key={ci}
                    sx={{
                      width: _.width,
                      maxWidth: _.maxWidth || 0,
                      pr: 1,
                      pl: 1,
                      borderRight: "1px solid #E9EBEB",
                      position: isFirst ? "sticky" : "static",
                      left: 0,
                      zIndex: isFirst ? 1 : 0,
                      background: "#fff",
                    }}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Skeleton
                      sx={{ width: "100%", height: "100%", maxHeight: 20 }}
                    />
                  </Stack>
                );
              })
            )}
          </Stack>
        );
      })}
    </Stack>
  );
};

export default TableBodyLoading;
