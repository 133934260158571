import { Form as FormikForm, FormikProvider, useFormik } from "formik";
import React from "react";

import FormikAutocomplete from "components/forms/FormikAutocomplete";
import FormikTextField from "components/forms/FormikTextField";

const TrackTimeForm = () => {
  const formik = useFormik({
    initialValues: {
      project: null,
      task: null,
      description: "",
    },
    onSubmit: () => {},
    enableReinitialize: true,
  });

  return (
    <FormikProvider value={formik}>
      <FormikForm>
        <FormikAutocomplete
          name="project"
          label="Project"
          //   label={<FormattedMessage id="LOGIN.FORM.EMAIL" />}
          options={[]}
        />
        <FormikAutocomplete
          name="task"
          label="Task"
          //   label={<FormattedMessage id="LOGIN.FORM.EMAIL" />}
          options={[]}
        />
        <FormikTextField
          name="description"
          label="Description"
          multiline
          rows={4}
        />
      </FormikForm>
    </FormikProvider>
  );
};

export default TrackTimeForm;
