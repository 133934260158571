import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";

import { ReactComponent as CheckIcon } from "assets/icons/check-icon.svg";

const subscriptionPlans = [
  {
    type: "minor",
    title: "Minor",
    color: "#d7e5f8",
    price: 0,

    features: [
      {
        text: "Up to 3 clients",
        included: true,
      },
      {
        text: "Up to 3 projects",
        included: true,
      },
      {
        text: "One Invoice template",
        included: true,
      },
      {
        text: "Task & Time Tracking",
        included: true,
      },
      {
        text: "Expense Tracking",
        included: true,
      },
      {
        text: "Workflows",
        included: false,
      },
      {
        text: "3rd Party Integrations",
        included: false,
      },
    ],
  },
  {
    type: "medium",
    title: "Medium",
    color: "#0775DA",
    price: 20,

    features: [
      {
        text: "Unlimited clients",
        included: true,
      },
      {
        text: "Unlimited projects",
        included: true,
      },
      {
        text: "5 Invoice template",
        included: true,
      },
      {
        text: "Task & Time Tracking",
        included: true,
      },
      {
        text: "Expense Tracking",
        included: true,
      },
      {
        text: "Workflows",
        included: false,
      },
      {
        text: "3rd Party Integrations",
        included: false,
      },
    ],
  },
  {
    type: "major",
    title: "Major",
    color: "#FFD700",

    price: 40,

    features: [
      {
        text: "Unlimited clients",
        included: true,
      },
      {
        text: "Unlimited projects",
        included: true,
      },
      {
        text: "5 Invoice template",
        included: true,
      },
      {
        text: "Task & Time Tracking",
        included: true,
      },
      {
        text: "Expense Tracking",
        included: true,
      },
      {
        text: "Workflows",
        included: true,
      },
      {
        text: "3rd Party Integrations",
        included: true,
      },
    ],
  },
];

const SubscriptionPage = () => {
  return (
    <Box sx={{ py: 4, px: 3 }}>
      <Typography variant="h4" mb={2}>
        Subscription Plan
      </Typography>

      {/* Subscription Plans */}
      <Grid container spacing={2} mb={5}>
        {subscriptionPlans.map(({ type, title, color, price, features }) => {
          const activePlan = type === "minor";
          return (
            <Grid item xs={12} lg={4} key={type}>
              <Stack
                direction="column"
                justifyContent="space-between"
                sx={{
                  border: "1px solid #E9EBEB",
                  borderTop: (theme) => `3px solid ${color}`,
                }}
                height="100%"
                p={3}
              >
                <Box>
                  <Typography fontWeight="bold" fontSize={18}>
                    {title}
                  </Typography>

                  <Box position="relative" mt={1} mb={3}>
                    <Typography fontWeight="bold" fontSize={35} pl={1.5}>
                      <Typography
                        component="span"
                        fontWeight="bold"
                        fontSize={20}
                        position="absolute"
                        left={0}
                      >
                        $
                      </Typography>
                      {price}{" "}
                      <Typography
                        component="span"
                        fontWeight="bold"
                        fontSize={16}
                        color={(theme) => theme.palette.primary.main}
                      >
                        / month
                      </Typography>
                    </Typography>
                  </Box>

                  <Button
                    fullWidth
                    // disabled={activePlan}
                    variant={activePlan ? "outlined" : "contained"}
                    sx={{ mt: "auto" }}
                  >
                    {activePlan ? "Active Plan" : "Choose Plan"}
                  </Button>

                  <Box
                    my={3}
                    sx={{
                      height: "2px",
                      // bgcolor: (theme) => theme.palette.primary.main,
                      bgcolor: "#E9EBEB",
                    }}
                  ></Box>

                  <Typography fontWeight="bold" mb={1.5}>
                    What's Included
                  </Typography>

                  <Stack direction="column" spacing={0.5} mb={2}>
                    {features.map(({ text, included }, index) => (
                      <Stack key={index} direction="row">
                        <Box
                          sx={{
                            mr: 1.5,
                            color: (theme) => theme.palette.primary.main,

                            svg: {
                              width: 18,
                              height: 18,
                            },
                          }}
                        >
                          <CheckIcon />
                        </Box>
                        <Typography
                          sx={{
                            textDecoration: included ? "none" : "line-through",
                            opacity: included ? 1 : 0.5,
                          }}
                        >
                          {text}
                        </Typography>
                      </Stack>
                    ))}
                  </Stack>
                </Box>
              </Stack>
            </Grid>
          );
        })}
      </Grid>

      <Typography variant="h4" mb={2}>
        Billing Information
      </Typography>
      <Box sx={{ border: "1px solid #E9EBEB" }} height={150} mb={5}></Box>

      <Typography variant="h4" mb={2}>
        Invoices
      </Typography>
      <Box sx={{ border: "1px solid #E9EBEB" }} mb={5}>
        {/* No Invoices */}
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          p={3}
        >
          <Typography variant="body2">
            You don't have any subscription invoices.
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};

export default SubscriptionPage;
