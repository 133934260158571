import { Box } from "@mui/material";
import React from "react";

import TimesheetTimelineActions from "./TimesheetTimelineActions";
import TimesheetVisTimeline from "./TimesheetVisTimeline/TimesheetVisTimeline";

const TimesheetTimeline = () => {
  return (
    <Box sx={{ py: 4, px: 3 }}>
      <Box pb={1} mb={2} bgcolor="#fff">
        <TimesheetTimelineActions />
      </Box>

      <TimesheetVisTimeline />
    </Box>
  );
};

export default TimesheetTimeline;
