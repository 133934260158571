import { Stack } from "@mui/material";
import React from "react";

const Alert = () => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        minHeight: { xs: "auto", lg: 37 },
        height: "auto",
        fontSize: 14,
        fontWeight: 600,
      }}
      px={2}
      textAlign="center"
      py={1}
    >
      You don't have an active subscription. Start your 7 day free trial now!
    </Stack>
  );
};

export default Alert;
