import { Box, ButtonBase, Stack } from "@mui/material";

import { ReactComponent as ArrowsDiagonalIcon } from "assets/icons/arrows-diagonal-icon.svg";
import { ReactComponent as DescriptionIcon } from "assets/icons/description-icon.svg";

export const ProjectFormatter = ({ name }: { name: string }) => {
  return (
    <Stack sx={{ height: "100%" }} direction="row" alignItems="center">
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          width: 230,
          height: "100%",
          borderRight: "1px solid #EEE",
          position: "relative",

          transition:
            "background-color 150ms ease-in-out, color 150ms ease-in-out",
          "&:hover": {
            color: (theme) => theme.palette.primary.main,
            backgroundColor: "#d7e5f8",
          },

          "&:hover .open": {
            opacity: 1,
          },
        }}
        justifyContent="flex-start"
        component={ButtonBase}
      >
        <Box sx={{ ml: 1 }}>{name}</Box>

        <Box
          className="open"
          sx={{
            opacity: 0,
            position: "absolute",
            right: 10,
            color: (theme) => theme.palette.primary.main,
            transition: "opacity 150ms ease-in-out",
          }}
        >
          <ArrowsDiagonalIcon />
        </Box>
      </Stack>

      <Stack
        sx={{
          width: 50,
          height: "100%",
          cursor: "pointer",

          color: (theme) => theme.palette.grey[600],

          transition:
            "background-color 150ms ease-in-out, color 150ms ease-in-out",
          "&:hover": {
            color: (theme) => theme.palette.primary.main,
            backgroundColor: "#d7e5f8",
          },
        }}
        alignItems="center"
        justifyContent="center"
        component={ButtonBase}
      >
        <DescriptionIcon />
      </Stack>
    </Stack>
  );
};
