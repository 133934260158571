import React, { FC } from "react";
import { Column } from "react-table";

import Table from "components/Table";
import { DateFormatter } from "components/Table/formatters/DateFormatter";

import { ProposalFormatter } from "./components/ProposalFormatter";

interface IProposalsListTable {}

const ProposalsListTable: FC<IProposalsListTable> = () => {
  const columns: Column<any>[] = [
    {
      accessor: "name",
      Header: "Proposal",

      Cell: ({ value }) => <ProposalFormatter name={value} />,

      padding: 0,
      width: 280,
    },
    {
      accessor: "issuedOn",
      Header: "Issued On",

      Cell: ({ value }) => <DateFormatter date={value} />,

      padding: 0,
      width: 200,
    },
  ];

  return (
    <>
      <Table
        data={[
          {
            name: "Blank Proposal",
            issuedOn: new Date(),
          },
        ]}
        // data={data}
        columns={columns}
        // sort={sortOptions}
        // pagination={paginationOptions}
        // status="loading"
      />
    </>
  );
};

export default ProposalsListTable;
