import React, { FC } from "react";
import { Column } from "react-table";

import Table from "components/Table";
import { DateFormatter } from "components/Table/formatters/DateFormatter";
import { priceFormatter } from "components/Table/formatters/priceFormatter";
import { ClientFormatter } from "containers/clients/clients-list/ClientsListTable/components/ClientFormatter";
import { ProjectFormatter } from "containers/projects/projects-list/ProjectsListTable/components/ProjectFormatter";

import { InvoiceFormatter } from "./components/InvoiceFormatter";
interface IInvoicesListTable {}

const InvoicesListTable: FC<IInvoicesListTable> = () => {
  const columns: Column<any>[] = [
    {
      accessor: "invoiceNo",
      Header: "Invoice",

      Cell: ({ value }) => <InvoiceFormatter code={value} />,

      padding: 0,
      width: 250,
    },
    {
      accessor: "amount",
      Header: "Amount",

      Cell: ({ value }) => <>{priceFormatter(value)}</>,

      width: 200,
    },
    {
      accessor: "project",
      Header: "Project",

      Cell: ({ value }) => <ProjectFormatter name={value} />,

      padding: 0,
      width: 250,
    },
    {
      accessor: "client",
      Header: "Client",

      Cell: ({ value }) => <ClientFormatter name={value} />,

      padding: 0,
      width: 250,
    },
    {
      accessor: "issuedOn",
      Header: "Issued On",

      Cell: ({ value }) => <DateFormatter date={value} />,

      padding: 0,
      width: 200,
    },
    {
      accessor: "dueDate",
      Header: "Due Date",

      Cell: ({ value }) => <DateFormatter date={value} />,

      padding: 0,
      width: 200,
    },
  ];

  return (
    <>
      <Table
        data={[
          {
            invoiceNo: "#0001",
            amount: 1240000,
            project: "Prvi Projekat",
            client: "Zerox Kompanija",
            issuedOn: new Date(),
            dueDate: new Date(),
          },
        ]}
        // data={data}
        columns={columns}
        // sort={sortOptions}
        // pagination={paginationOptions}
        // status="loading"
      />
    </>
  );
};

export default InvoicesListTable;
