import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);

declare module "dayjs" {
  interface Dayjs {
    lastWorkingDay(): dayjs.Dayjs;
    getDuration(startDate: Date, endDate: Date): string;
  }
}

dayjs.prototype.lastWorkingDay = () => {
  const currentDay = dayjs();

  if (currentDay.day() === 6) {
    return currentDay.subtract(1, "day");
  } else if (currentDay.day() === 0) {
    return currentDay.subtract(2, "day");
  }
  return currentDay;
};

dayjs.prototype.getDuration = (startDate: Date, endDate: Date) => {
  const duration = dayjs(endDate).diff(startDate);
  const momentDuration = dayjs.duration(duration);
  const formattedDuration = momentDuration.format("HH:mm:ss");

  return formattedDuration;
};
export default dayjs;
