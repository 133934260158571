import { Box } from "@mui/material";
import React from "react";

import EmployeesTimelineActions from "./EmployeesTimelineActions";
import EmployeesVisTimeline from "./EmployeesVisTimeline/EmployeesVisTimeline";

const EmployeesTimeline = () => {
  return (
    <Box sx={{ py: 4, px: 3 }}>
      <Box pb={1} mb={2} bgcolor="#fff">
        <EmployeesTimelineActions />
      </Box>

      <EmployeesVisTimeline />
    </Box>
  );
};

export default EmployeesTimeline;
