import { Box, Stack } from "@mui/material";
import React from "react";

import TimesheetBigCalendar from "./TimesheetBigCalendar/TimesheetBigCalendar";

const TimesheetCalendar = () => {
  return (
    <>
      <Box sx={{ py: 4, px: 3 }}>
        <Stack spacing={2} direction="row">
          <Box width="100%">
            <TimesheetBigCalendar />
          </Box>
          <Box width="350px" sx={{ border: "1px solid #E9EBEB" }}></Box>
        </Stack>
      </Box>
    </>
  );
};

export default TimesheetCalendar;
