import { Box, LinearProgress, Stack } from "@mui/material";

export const ProjectProgressFormatter = ({
  progress,
}: {
  progress: number;
}) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={{ width: "100%", height: "100%" }}
      spacing={1}
    >
      <Box sx={{ width: "100%", maxWidth: "100px" }}>
        <LinearProgress
          color="primary"
          variant="determinate"
          value={progress}
          sx={{ height: 10, bgcolor: "#e9e9e9", borderRadius: "2px" }}
        />
      </Box>
      <Box sx={{ width: 10, fontSize: 12, pb: "2px" }}>{progress}%</Box>
    </Stack>
  );
};
