import React from "react";
import { Route, Switch } from "react-router-dom";

import Header from "components/pages/Header/Header";

import FAQCategories from "./faq-categories";

const FAQPage = () => {
  return (
    <>
      <Header label="Frequently Asked Questions" />

      <Switch>
        <Route exact path="/faq" component={FAQCategories} />
      </Switch>
    </>
  );
};

export default FAQPage;
