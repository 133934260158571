import { Avatar, AvatarGroup, Stack } from "@mui/material";
import React, { FC } from "react";
import { Column } from "react-table";

import Table from "components/Table";
import { DateFormatter } from "components/Table/formatters/DateFormatter";
import { ClientFormatter } from "containers/clients/clients-list/ClientsListTable/components/ClientFormatter";

import { ProjectFormatter } from "./components/ProjectFormatter";
import { ProjectProgressFormatter } from "./components/ProjectProgressFormatter";
import { ProjectStatusFormatter } from "./components/ProjectStatusFormatter";

interface IProjectsListTable {}

const ProjectsListTable: FC<IProjectsListTable> = () => {
  const columns: Column<any>[] = [
    // {
    //   id: "checkbox",

    //   Header: "",

    //   padding: 0,
    //   width: 50,
    // },
    {
      accessor: "name",
      Header: "Project",

      Cell: ({ value }) => <ProjectFormatter name={value} />,

      padding: 0,
      width: 280,
    },
    {
      accessor: "assigned",
      Header: "Assigned",

      Cell: ({ value }) => (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{
            height: "100%",
            cursor: "pointer",
          }}
        >
          <AvatarGroup
            max={3}
            spacing={30}
            sx={{
              ".MuiAvatar-root": {
                width: 35,
                height: 35,
                borderRadius: "50%",
                fontSize: 14,
              },
            }}
          >
            <Avatar
              sx={{
                mx: 1,
                backgroundColor: (theme) => theme.palette.primary.main,
              }}
              variant="rounded"
            >
              {value}
            </Avatar>
            <Avatar
              sx={{
                mx: 1,
                backgroundColor: (theme) => theme.palette.primary.main,
              }}
              variant="rounded"
            >
              {value}
            </Avatar>
            <Avatar
              sx={{
                mx: 1,
                backgroundColor: (theme) => theme.palette.primary.main,
              }}
              variant="rounded"
            >
              {value}
            </Avatar>
            <Avatar
              sx={{
                mx: 1,
                backgroundColor: (theme) => theme.palette.primary.main,
              }}
              variant="rounded"
            >
              {value}
            </Avatar>
          </AvatarGroup>
        </Stack>
      ),

      padding: 0,
      width: 200,
    },
    {
      accessor: "client",
      Header: "Client",

      Cell: ({ value }) => <ClientFormatter name={value} />,

      padding: 0,
      width: 250,
    },
    {
      accessor: "status",
      Header: "Status",

      Cell: ({ value }) => <ProjectStatusFormatter status={value} />,
      width: 150,
    },
    {
      accessor: "progress",
      Header: "Progress",

      Cell: ({ value }) => <ProjectProgressFormatter progress={value} />,

      padding: 0,
      width: 200,
    },
    {
      accessor: "startDate",
      Header: "Start Date",

      Cell: ({ value }) => <DateFormatter date={value} />,

      padding: 0,
      width: 200,
    },
    {
      accessor: "dueDate",
      Header: "Due Date",

      Cell: ({ value }) => <DateFormatter date={value} />,

      padding: 0,
      width: 200,
    },
  ];

  return (
    <>
      <Table
        data={[
          {
            name: "Prvi Projekat",
            assigned: "NN",
            client: "Zerox Kompanija",
            status: "on-track",
            progress: 84,
            startDate: new Date(),
            dueDate: new Date(),
          },
          {
            name: "Drugi Projekat",
            assigned: "NN",
            client: "Zerox Kompanija",
            status: "off-track",
            progress: 32,
            startDate: new Date(),
            dueDate: new Date(),
          },
          {
            name: "Prvi Projekat",
            assigned: "NN",
            client: "Zerox Kompanija",
            status: "at-risk",
            progress: 64,
            startDate: new Date(),
            dueDate: new Date(),
          },
          {
            name: "Drugi Projekat",
            assigned: "NN",
            client: "Zerox Kompanija",
            status: "off-track",
            progress: 12,
            startDate: new Date(),
            dueDate: new Date(),
          },
          {
            name: "Prvi Projekat",
            assigned: "NN",
            client: "Zerox Kompanija",
            status: "on-track",
            progress: 89,
            startDate: new Date(),
            dueDate: new Date(),
          },
          {
            name: "Drugi Projekat",
            assigned: "NN",
            client: "Zerox Kompanija",
            status: "off-track",
            progress: 41,
            startDate: new Date(),
            dueDate: new Date(),
          },
        ]}
        // data={data}
        columns={columns}
        // sort={sortOptions}
        // pagination={paginationOptions}
        // status="loading"
      />
    </>
  );
};

export default ProjectsListTable;
