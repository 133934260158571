import { Box, Stack } from "@mui/material";
import React from "react";

import DocumentationDocumentEditor from "./DocumentationDocument/DocumentationDocumentEditor";
import DocumentationListActions from "./DocumentationListActions";

const DocumentationOverview = () => {
  return (
    <Stack direction="row" height="100%">
      <Box
        width={280}
        minWidth={280}
        sx={{ borderRight: "1px solid #E9EBEB", py: 4 }}
        // height="100%"
      >
        <DocumentationListActions />
      </Box>
      <Box sx={{ width: "100%" }}>
        <DocumentationDocumentEditor />
      </Box>
    </Stack>
  );
};

export default DocumentationOverview;
