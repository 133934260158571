import {
  Box,
  ButtonBase,
  Divider,
  Popover,
  Stack,
  useTheme,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { PopupState, bindPopover } from "material-ui-popup-state/core";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";

import { ReactComponent as AppsIcon } from "assets/icons/apps-icon.svg";
import { ReactComponent as BuildingIcon } from "assets/icons/building-icon.svg";
import { ReactComponent as DiamondIcon } from "assets/icons/diamond-icon.svg";
import { ReactComponent as HelpIcon } from "assets/icons/help-icon.svg";
import { ReactComponent as ListCheckIcon } from "assets/icons/list-check-icon.svg";
import { ReactComponent as LogoutIcon } from "assets/icons/logout-icon.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings-icon.svg";
import { ReactComponent as UserCircleIcon } from "assets/icons/user-circle-icon.svg";

interface IProfilePopover {
  anchorEl: Element;
  popupState: PopupState;
}

const ProfilePopover: FC<IProfilePopover> = ({ anchorEl, popupState }) => {
  const theme = useTheme();

  const history = useHistory();
  const queryClient = useQueryClient();

  const handleLogout = () => {
    localStorage.removeItem("JWToken");
    queryClient.removeQueries([]);
    // logout({ returnTo: `${window.location.origin}/login` });
  };

  const profileMenuList = [
    {
      icon: <UserCircleIcon />,
      text: "My Profile",
      link: "/profile",
    },
    {
      icon: <BuildingIcon />,
      text: "Company",
      link: "/profile/company",
    },
    {
      icon: <DiamondIcon />,
      text: "Subscription",
      link: "/profile/subscription",
    },
    {
      icon: <AppsIcon />,
      text: "Integrations",
      link: "/profile/integrations",
    },
    {
      icon: <SettingsIcon />,
      text: "Settings",
      link: "/profile/settings",

      divider: true,
    },
    {
      icon: <ListCheckIcon />,
      text: "Roadmap",
      link: "/roadmap",
    },
    {
      icon: <HelpIcon />,
      text: "FAQ",
      link: "/faq",

      divider: true,
    },

    {
      icon: <LogoutIcon />,
      text: "Logout",
      color: theme.palette.error.main,
      link: "/login",
      onClick: handleLogout,
    },
  ];

  return (
    <Popover
      {...bindPopover(popupState)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{
        mt: "-3px",
      }}
    >
      <Stack direction="column" sx={{ width: "180px", fontSize: 15, py: 1 }}>
        {profileMenuList.map(
          ({ icon, text, link, onClick, divider, color }) => (
            <React.Fragment key={link}>
              <Stack
                component={ButtonBase}
                py={1.3}
                px={1.5}
                justifyContent="flex-start"
                fontWeight={500}
                onClick={() => {
                  if (onClick) {
                    onClick();
                  }

                  if (link) {
                    history.push(link);
                  }

                  popupState.close();
                }}
                sx={{
                  color: (theme) => color || theme.palette.grey[700],

                  svg: {
                    width: 20,
                    height: 20,
                  },

                  transition: "background-color 150ms ease-in-out",
                  ":hover": {
                    backgroundColor: (theme) => theme.palette.grey[200],
                  },
                }}
                direction="row"
              >
                <Box sx={{ width: 20 }} mr={1.5}>
                  {icon}
                </Box>
                <Box>{text}</Box>
              </Stack>

              {divider && <Divider sx={{ my: 1 }} />}
            </React.Fragment>
          )
        )}
      </Stack>
    </Popover>
  );
};

export default ProfilePopover;
