import dayjs from "dayjs";
import React from "react";
import { Calendar, dayjsLocalizer } from "react-big-calendar";

const localizer = dayjsLocalizer(dayjs);

const TimesheetBigCalendar = () => {
  return (
    <>
      <Calendar
        localizer={localizer}
        events={[
          {
            start: new Date(2023, 3, 25, 9),
            end: new Date(2023, 3, 25, 15),
            title: "Some title",
          },
          {
            start: new Date(2023, 3, 25, 9),
            end: new Date(2023, 3, 25, 18),
            title: "Some title",
          },
        ]}
        views={["day", "week", "month"]}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 900 }}
      />
    </>
  );
};

export default TimesheetBigCalendar;
