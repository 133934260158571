import { Box } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import Timeline from "react-vis-timeline-2";

const items = [
  {
    id: 1,
    group: 1,
    start: new Date(2023, 3, 20),
    end: new Date(2023, 4, 12),
    content: "",
  },
  {
    id: 2,
    group: 2,
    start: new Date(2023, 1, 14),
    content: "",
  },
  {
    id: 3,
    group: 3,
    start: new Date(2023, 3, 11),
    end: new Date(2023, 4, 6),
    content: "",
  },
  {
    id: 4,
    group: 4,
    start: new Date(2022, 11, 12),
    end: new Date(2023, 0, 12),
    content: "",
  },
  {
    id: 5,
    group: 5,
    start: new Date(2023, 3, 20),
    end: new Date(2023, 6, 24),
    content: "",
  },
  {
    id: 6,
    group: 6,
    start: new Date(2023, 2, 20),
    end: new Date(2023, 7, 12),
    content: "",
  },
  {
    id: 7,
    group: 7,
    start: new Date(2023, 3, 20),
    end: new Date(2023, 4, 12),
    content: "",
  },
  {
    id: 8,
    group: 8,
    start: new Date(2023, 1, 14),
    end: new Date(),
    content: "",
  },
];

const groups = [
  {
    id: 1,
    content: "Prvi Projekat",
  },
  {
    id: 2,
    content: "Drugi Projekat",
  },
  {
    id: 3,
    content: "Prvi Projekat",
  },
  {
    id: 4,
    content: "Drugi Projekat",
  },
  {
    id: 5,
    content: "Prvi Projekat",
  },
  {
    id: 6,
    content: "Drugi Projekat",
  },
  {
    id: 7,
    content: "Prvi Projekat",
  },
  {
    id: 8,
    content: "Drugi Projekat",
  },
];

const ProjectsVisTimeline = () => {
  return (
    <Box position="relative">
      <Timeline
        options={{
          orientation: "top",

          width: "100%",
          zoomMin: 500000000,
          zoomMax: 45000000000,
          start: dayjs().subtract(120, "days").toDate(),
          end: dayjs().add(120, "days").toDate(),

          margin: {
            axis: 6,
            item: {
              vertical: 12,
              horizontal: 0,
            },
          },

          groupHeightMode: "fixed",

          onInitialDrawComplete: () => {
            console.log("loaded");
          },
        }}
        initialGroups={groups}
        initialItems={items}
      />
    </Box>
  );
};

export default ProjectsVisTimeline;
