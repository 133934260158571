import { CustomContentProps } from "notistack";
import { forwardRef } from "react";

import SnackbarBaseComponent from "./SnackbarBaseComponent";

const SnackbarSuccessComponent = forwardRef<HTMLDivElement, CustomContentProps>(
  ({ id, ...props }, ref) => {
    return (
      <SnackbarBaseComponent id={id} color="#00c875" {...props} ref={ref} />
    );
  }
);

export default SnackbarSuccessComponent;
