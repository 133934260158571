import { Avatar, Box, ButtonBase, Stack } from "@mui/material";

import { ReactComponent as ArrowsDiagonalIcon } from "assets/icons/arrows-diagonal-icon.svg";

export const ClientFormatter = ({ name }: { name: string }) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        height: "100%",
        cursor: "pointer",

        transition:
          "background-color 150ms ease-in-out, color 150ms ease-in-out",
        "&:hover": {
          color: (theme) => theme.palette.primary.main,
          backgroundColor: "#d7e5f8",
        },

        "&:hover .open": {
          opacity: 1,
        },
      }}
      justifyContent="flex-start"
      component={ButtonBase}
    >
      <Avatar
        sx={{
          mx: 1,
          backgroundColor: (theme) => theme.palette.primary.main,
          width: 35,
          height: 35,
        }}
        variant="rounded"
      >
        Z
      </Avatar>
      <Box>{name}</Box>

      <Box
        className="open"
        sx={{
          opacity: 0,
          position: "absolute",
          right: 10,
          color: (theme) => theme.palette.primary.main,
          transition: "opacity 150ms ease-in-out",
        }}
      >
        <ArrowsDiagonalIcon />
      </Box>
    </Stack>
  );
};
