import { Box, Grid, Link, Stack, Typography } from "@mui/material";
import React from "react";

import logo from "assets/logos/logo.svg";

import LoginForm from "./LoginForm/LoginForm";

const LoginPage = () => {
  return (
    <Grid container sx={{ height: "100vh" }}>
      <Grid xs={6}>
        <Stack height="100%" alignItems="center" justifyContent="center">
          <Stack width="409px" direction="column" alignItems="center">
            {/* Logo */}
            <Box
              component="img"
              src={logo}
              alt="CompaVion Logo"
              height={44}
              mb={6}
            />

            {/* Title */}
            <Box textAlign="center" mb={6}>
              <Typography variant="h1" mb={1.5}>
                Log in to your Account
              </Typography>
              <Typography variant="body2">
                Welcome back! Select method to log in:
              </Typography>
            </Box>

            {/* Social Media Logins */}

            {/* Logins */}
            <Box width="100%" mb={4}>
              <LoginForm />
            </Box>

            {/* External Links */}
            <Typography variant="body2">
              Don't have an account? <Link>Create an account</Link>
            </Typography>
          </Stack>
        </Stack>
      </Grid>
      <Grid
        xs={6}
        sx={{ background: (theme) => theme.palette.primary.main }}
      ></Grid>
    </Grid>
  );
};

export default LoginPage;
