import { Search as SearchIcon } from "@mui/icons-material";
import { Grid, InputAdornment, TextField } from "@mui/material";
import React from "react";

const ProjectsTimelineActions = () => {
  return (
    <Grid container spacing={2}>
      {/* Search Field */}
      <Grid item xs={12} md="auto">
        <TextField
          sx={{
            width: 160,
            transition: "width 150ms ease-in-out",

            m: 0,
            ".MuiInputBase-root": {
              height: 36,
              px: 2,
            },
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#E9EBEB",
            },

            "&:hover, &:focus, &:focus-within": {
              width: "100%",
            },
          }}
          placeholder="Search..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ProjectsTimelineActions;
