// @ts-nocheck
/* eslint-disable */

import React, { useEffect, useState } from "react";

import { Box, CircularProgress } from "@mui/material";

const RoadmapCanny = () => {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (function (w, d, i, s) {
      function l() {
        if (!d.getElementById(i)) {
          var f = d.getElementsByTagName(s)[0],
            e = d.createElement(s);
          (e.type = "text/javascript"),
            (e.async = !0),
            (e.src = "https://canny.io/sdk.js"),
            f.parentNode.insertBefore(e, f);
        }
      }
      if ("function" != typeof w.Canny) {
        var c = function () {
          c.q.push(arguments);
        };
        (c.q = []),
          (w.Canny = c),
          "complete" === d.readyState
            ? l()
            : w.attachEvent
            ? w.attachEvent("onload", l)
            : w.addEventListener("load", l, !1);
      }
    })(window, document, "canny-jssdk", "script");

    const BoardToken = "3ce83a1c-4a0d-56b4-5af6-db48d0e633bb";
    Canny("render", {
      boardToken: BoardToken,
      basePath: null,
      ssoToken: null, // See step 3,
      onLoadCallback: () => {
        setLoading(false);
      },
      theme: "light", // options: light [default], dark, auto
    });
  }, []);

  return (
    <Box sx={{ py: 4, px: 3 }} position="relative">
      {/* Loader */}
      <Box
        sx={{
          left: "50%",
          transform: "translateX(-50%)",
        }}
        position="absolute"
        py={12}
        hidden={!loading}
      >
        <CircularProgress />
      </Box>

      {/* Canny */}
      <Box
        data-canny
        sx={
          {
            // "#canny-iframe": {
            //   height: "100%!important",
            // },
          }
        }
      />
    </Box>
  );
};

export default RoadmapCanny;
