import { SnackbarProvider as SnackbarActualProvider } from "notistack";
import React, { FC } from "react";

import SnackbarErrorComponent from "components/notistack/SnackbarErrorComponent";
import SnackbarSuccessComponent from "components/notistack/SnackbarSuccessComponent";
import { WithChildren } from "utils/types";

const SnackbarProvider: FC<WithChildren> = ({ children }) => {
  return (
    <SnackbarActualProvider
      maxSnack={3}
      Components={{
        success: SnackbarSuccessComponent,
        error: SnackbarErrorComponent,
      }}
    >
      {children}
    </SnackbarActualProvider>
  );
};

export default SnackbarProvider;
