import { Box } from "@mui/material";
import React from "react";

import ProposalsListActions from "./ProposalsListActions";
import ProposalsListTable from "./ProposalsListTable/ProposalsListTable";

const ProposalsList = () => {
  return (
    <Box sx={{ py: 4, pl: 3 }}>
      <Box pb={1} pr={3} mb={2} bgcolor="#fff">
        <ProposalsListActions />
      </Box>
      <ProposalsListTable />
    </Box>
  );
};

export default ProposalsList;
