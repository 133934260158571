import { Box, Divider, MenuList, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";

import { RoleType } from "api/auth/types";
import { ReactComponent as ClientsIcon } from "assets/icons/clients-icon.svg";
import { ReactComponent as ClockIcon } from "assets/icons/clock-icon.svg";
import { ReactComponent as DashboardIcon } from "assets/icons/dashboard-icon.svg";
import { ReactComponent as DocumentationIcon } from "assets/icons/documentation-icon.svg";
import { ReactComponent as EmployeesIcon } from "assets/icons/employees-icon.svg";
import { ReactComponent as FinancesIcon } from "assets/icons/finances-icon.svg";
import { ReactComponent as InvoicesIcon } from "assets/icons/invoices-icon.svg";
import { ReactComponent as ProjectsIcon } from "assets/icons/projects-icon.svg";
import { ReactComponent as ProposalsIcon } from "assets/icons/proposals-icon.svg";
import { hasPermissions, hasRole } from "components/stores/UserStore";
import { PermissionsType } from "components/stores/UserStore/permissions";

import SidebarMenuItem, { SidebarMenuItemsProps } from "./SidebarMenuItem";

export type SidebarMenuListChild = Array<
  SidebarMenuItemsProps & {
    requiredPermissions?: PermissionsType[];
    requiredRole?: RoleType[];
  }
>;

type SidebarMenuList = Array<{
  id: string;
  text: string | JSX.Element;
  list: SidebarMenuListChild;
}>;

const sidebarMenuListOperational: SidebarMenuListChild = [
  {
    icon: <DashboardIcon />,
    text: <FormattedMessage id="HEADER.DASHBOARD" />,
    link: "/dashboard",
  },
  {
    icon: <ClientsIcon />,
    text: <FormattedMessage id="HEADER.CLIENTS" />,
    link: "/clients",
  },
  {
    icon: <ProjectsIcon />,
    text: <FormattedMessage id="HEADER.PROJECTS" />,
    link: "/projects",
  },
  {
    icon: <DocumentationIcon />,
    text: <FormattedMessage id="HEADER.DOCUMENTATION" />,
    link: "/documentation",
  },
];

const sidebarMenuListAccounting: SidebarMenuListChild = [
  {
    icon: <InvoicesIcon />,
    text: <FormattedMessage id="HEADER.INVOICES" />,
    link: "/invoices",
  },
  {
    icon: <ProposalsIcon />,
    text: <FormattedMessage id="HEADER.PROPOSALS" />,
    link: "/proposals",
  },
  {
    icon: <FinancesIcon />,
    text: <FormattedMessage id="HEADER.FINANCES" />,
    link: "/finances",
  },
];

const sidebarMenuListManagement: SidebarMenuListChild = [
  {
    icon: <EmployeesIcon />,
    text: <FormattedMessage id="HEADER.EMPLOYEES" />,
    link: "/employees",
  },
  {
    icon: <ClockIcon />,
    text: <FormattedMessage id="HEADER.TIMESHEET" />,
    link: "/timesheet",
  },
];

const sidebarMenuList: SidebarMenuList = [
  {
    id: "operational",
    text: <FormattedMessage id="HEADER.OPERATIONAL" />,
    list: sidebarMenuListOperational,
  },
  {
    id: "admin",
    text: <FormattedMessage id="HEADER.ACCOUNTING" />,
    list: sidebarMenuListAccounting,
  },
  {
    id: "system",
    text: <FormattedMessage id="HEADER.MANAGEMENT" />,
    list: sidebarMenuListManagement,
  },
];

interface Props {
  onClose?: () => void;
}

const SidebarMenu = ({ onClose }: Props) => {
  return (
    <MenuList
      sx={{
        overflowY: "auto",
        overflowX: "hidden",
        maxHeight: "calc(100% - 45px)",
        pt: 0,
        msOverflowStyle: "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      {sidebarMenuList.map(({ id, list, text }) => {
        const filteredList = list.filter(
          ({ requiredRole, requiredPermissions }) =>
            hasPermissions(requiredPermissions) && hasRole(requiredRole)
        );

        if (!filteredList.length) {
          return null;
        }

        return (
          <Box key={id}>
            <Typography
              variant="body2"
              sx={{
                color: "#252525",
                ml: 1,
                my: 1.5,
                width: "80%",
                overflowX: "hidden",
                textOverflow: "ellipsis",
                fontWeight: (t) => t.typography.fontWeightBold,
              }}
            >
              {text}
            </Typography>
            {filteredList.map(({ icon, text, link }) => {
              return (
                <SidebarMenuItem
                  key={link}
                  icon={icon}
                  text={text}
                  link={link}
                  onClick={onClose}
                />
              );
            })}
            <Divider sx={{ mx: 1 }} />
          </Box>
        );
      })}
    </MenuList>
  );
};

export default SidebarMenu;
