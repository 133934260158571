import { Box, Stack } from "@mui/material";

import { ReactComponent as EmailIcon } from "assets/icons/email-icon.svg";

export const EmailFormatter = ({ email }: { email: string | undefined }) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        height: "100%",
      }}
      justifyContent="center"
    >
      <Box
        sx={{
          mr: 1,

          color: (theme) => theme.palette.grey[600],
        }}
      >
        <EmailIcon />
      </Box>
      <Box>{email}</Box>
    </Stack>
  );
};
