import { FormControlLabel, Switch, SwitchProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useField } from "formik";
import React, { ReactElement } from "react";

import { MakeRequired } from "utils/types";

interface FormikSwitchProps extends MakeRequired<SwitchProps, "name"> {
  label: string | ReactElement;
  helperText?: string | ReactElement;
}
const CustomSwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

const FormikSwitch = ({
  name,
  label,
  helperText,
  ...rest
}: FormikSwitchProps) => {
  const [field, , helpers] = useField<unknown>(name);

  return (
    <>
      <FormControlLabel
        control={
          <CustomSwitch
            size="medium"
            sx={{ ml: 1, mr: 0 }}
            checked={Boolean(field.value)}
            {...field}
            {...rest}
            onChange={(e, newValue) => {
              helpers.setValue(newValue);
            }}
          />
        }
        label={label}
        sx={{ mb: 0, display: "block" }}
      />
    </>
  );
};

export default FormikSwitch;
