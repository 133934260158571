import {
  Search as SearchIcon,
  StarOutline as StarIcon,
} from "@mui/icons-material";
import {
  ButtonBase,
  Grid,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import React from "react";

import AddButton from "components/buttons/AddButton/AddButton";

const InvoicesListActions = () => {
  return (
    <Grid container spacing={1}>
      {/* ADD INVOICE */}
      <Grid item>
        <AddButton label="Add Invoice" />
      </Grid>

      <Grid item>
        <Stack
          height={36}
          width={36}
          alignItems="center"
          justifyContent="center"
          component={ButtonBase}
          sx={{
            border: "1px solid #E9EBEB",
            borderRadius: 1,
            transition: "background-color 150ms ease-in-out",

            ":hover": {
              backgroundColor: (theme) => theme.palette.grey[200],
            },
          }}
        >
          <StarIcon sx={{ color: "#0000008a" }} />
        </Stack>
      </Grid>

      {/* Search Field */}
      <Grid item xs={12} md="auto">
        <TextField
          sx={{
            width: 160,
            transition: "width 150ms ease-in-out",

            m: 0,
            ".MuiInputBase-root": {
              height: 36,
              px: 2,
            },
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#E9EBEB",
            },

            "&:hover, &:focus, &:focus-within": {
              width: "100%",
            },
          }}
          placeholder="Search..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};

export default InvoicesListActions;
