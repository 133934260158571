import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import React from "react";

import ChangeEmailForm from "./ChangeEmailForm/ChangeEmailForm";
import ChangePasswordForm from "./ChangePasswordForm/ChangePasswordForm";
import NotificationsForm from "./NotificationsForm/NotificationsForm";

const SettingsPage = () => {
  return (
    <Box sx={{ py: 4, px: 3 }}>
      <Typography variant="h4" mb={2}>
        Notifications
      </Typography>

      <Box
        sx={{
          border: "1px solid #E9EBEB",
        }}
        mb={3}
      >
        <NotificationsForm />
      </Box>

      <Typography variant="h4" mb={2}>
        Change Email
      </Typography>
      <Box
        sx={{
          border: "1px solid #E9EBEB",
        }}
        mb={3}
      >
        <ChangeEmailForm />
      </Box>

      <Typography variant="h4" mb={2}>
        Change Password
      </Typography>
      <Box
        sx={{
          border: "1px solid #E9EBEB",
        }}
        mb={3}
      >
        <ChangePasswordForm />
      </Box>

      <Typography variant="h4" mb={2}>
        Deactivate Account
      </Typography>
      <Box
        sx={{
          border: "1px solid #E9EBEB",
        }}
        mb={3}
      >
        <Box p={2}>
          <Typography>
            Are you certain you wish to deactivate your account? Please be aware
            that this action is irreversible and cannot be undone.
          </Typography>
        </Box>
        <Divider />
        <Stack direction="row" justifyContent="end" py={1} px={2}>
          <Button variant="contained" color="error">
            Deactivate
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default SettingsPage;
