import React from "react";
import { Route, Switch } from "react-router-dom";

import Header from "components/pages/Header/Header";

import CompanyPage from "./company";
import IntegrationsPage from "./integrations";
import MyProfilePage from "./my-profile";
import SettingsPage from "./settings";
import SubscriptionPage from "./subscription";

const ProfilePage = () => {
  return (
    <>
      <Header
        label="Profile"
        tabs={[
          {
            label: "My Profile",
            path: "/profile",
          },
          {
            label: "Company",
            path: "/profile/company",
          },
          {
            label: "Subscription",
            path: "/profile/subscription",
          },
          {
            label: "Integrations",
            path: "/profile/integrations",
          },
          {
            label: "Settings",
            path: "/profile/settings",
          },
        ]}
      />

      <Switch>
        <Route exact path="/profile" component={MyProfilePage} />
        <Route exact path="/profile/company" component={CompanyPage} />
        <Route
          exact
          path="/profile/subscription"
          component={SubscriptionPage}
        />
        <Route
          exact
          path="/profile/integrations"
          component={IntegrationsPage}
        />
        <Route exact path="/profile/settings" component={SettingsPage} />
      </Switch>
    </>
  );
};

export default ProfilePage;
