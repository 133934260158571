import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import React from "react";

import ProfileAboutMeEditor from "./ProfileAboutMeEditor/ProfileAboutMeEditor";
import ProfileSocialInformationForm from "./ProfileSocialInformationForm/ProfileSocialInformationForm";

const MyProfilePage = () => {
  return (
    <Box sx={{ py: 4, px: 3 }}>
      <Typography variant="h4" mb={2}>
        My Profile
      </Typography>

      <Box
        sx={{
          border: "1px solid #E9EBEB",
        }}
        p={3}
        mb={3}
      ></Box>

      <Typography variant="h4" mb={2}>
        About Me
      </Typography>

      <Box
        sx={{
          border: "1px solid #E9EBEB",
        }}
        mb={3}
      >
        <Box width="100%" height={250} overflow="scroll">
          <ProfileAboutMeEditor />
        </Box>
        <Divider />
        <Stack direction="row" justifyContent="end" py={1} px={2}>
          <Button variant="contained">Save</Button>
        </Stack>
      </Box>

      <Typography variant="h4" mb={2}>
        Social Information
      </Typography>

      <Box
        sx={{
          border: "1px solid #E9EBEB",
        }}
        mb={3}
      >
        <ProfileSocialInformationForm />
      </Box>

      <Typography variant="h4" mb={2}>
        Skills
      </Typography>

      <Box
        sx={{
          border: "1px solid #E9EBEB",
        }}
        p={3}
        mb={3}
      ></Box>

      <Typography variant="h4" mb={2}>
        Education
      </Typography>

      <Box
        sx={{
          border: "1px solid #E9EBEB",
        }}
        p={3}
        mb={3}
      ></Box>

      <Typography variant="h4" mb={2}>
        Employment
      </Typography>

      <Box
        sx={{
          border: "1px solid #E9EBEB",
        }}
        p={3}
        mb={3}
      ></Box>
    </Box>
  );
};

export default MyProfilePage;
