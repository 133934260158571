import React from "react";
import { Route, Switch } from "react-router-dom";

import Header from "components/pages/Header/Header";

import TimesheetCalendar from "./timesheet-calendar";
import TimesheetTimeline from "./timesheet-timeline";

const TimesheetPage = () => {
  return (
    <>
      <Header
        label="Timesheet"
        tabs={[
          {
            label: "Timeline",
            path: "/timesheet",
          },
          {
            label: "Calendar",
            path: "/timesheet/calendar",
          },
        ]}
      />

      <Switch>
        <Route exact path="/timesheet" component={TimesheetTimeline} />
        <Route exact path="/timesheet/calendar" component={TimesheetCalendar} />
      </Switch>
    </>
  );
};

export default TimesheetPage;
