import { Box } from "@mui/material";
import React from "react";

import InvoicesListActions from "./InvoicesListActions";
import InvoicesListTable from "./InvoicesListTable/InvoicesListTable";

const InvoicesList = () => {
  return (
    <Box sx={{ py: 4, pl: 3 }}>
      <Box pb={1} pr={3} mb={2} bgcolor="#fff">
        <InvoicesListActions />
      </Box>
      <InvoicesListTable />
    </Box>
  );
};

export default InvoicesList;
