import { Menu as MenuIcon } from "@mui/icons-material";
import { AppBar, IconButton, Stack, Toolbar } from "@mui/material";
import React from "react";

import Profile from "./Profile/Profile";
import TrackTime from "./TrackTime/TrackTime";

interface TopbarProps {
  isMobile: boolean;
  toggleMobileDrawer: () => void;
}

const Topbar = ({ isMobile, toggleMobileDrawer }: TopbarProps) => {
  // const isFetching = useIsFetching();

  return (
    <AppBar
      color="inherit"
      position={isMobile ? "fixed" : "sticky"}
      elevation={0}
      sx={{
        borderTop: "none",
        borderRight: "none",
        borderLeft: "none",
        borderBottom: "1px solid #E9EBEB",
        mt: -0.5,
        borderRadius: 0,
      }}
    >
      <Toolbar disableGutters>
        {isMobile && (
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ ml: 1 }}
            onClick={toggleMobileDrawer}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Stack
          sx={{
            // mt: 1,
            // ml: isMobile ? 1 : 3,
            // mr: 1,
            width: "100%",
            height: 64,
          }}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
            direction="row"
          ></Stack>

          <TrackTime />
          <Profile />
        </Stack>
      </Toolbar>
      {/* {isFetching ? <LinearProgress color="primary" /> : <Box height="4px" />} */}
    </AppBar>
  );
};

export default Topbar;
