import { Box, Button, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";

import DiscordLogoIMG from "assets/images/discord-logo.png";
import DropboxLogoIMG from "assets/images/dropbox-logo.png";
import GithubLogoIMG from "assets/images/github-logo.png";
import GmailLogoIMG from "assets/images/gmail-logo.png";
import HubspotLogoIMG from "assets/images/hubspot-logo.png";
import SlackLogoIMG from "assets/images/slack-logo.png";

const integrations = [
  {
    type: "gmail",
    image: GmailLogoIMG,
    title: "Gmail",
    description:
      "Connect your Gmail account to use your Google Account email address for client notifications.",
  },
  {
    type: "slack",
    image: SlackLogoIMG,
    title: "Slack",
    description:
      "Connect with Slack to receive real-time notifications and updates in your Slack channels.",
  },
  {
    type: "github",
    image: GithubLogoIMG,
    title: "github",
    description:
      "Integrate GitHub to connect with GitHub projects, effortlessly create issues, and manage pull requests.",
  },
  {
    type: "dropbox",
    image: DropboxLogoIMG,
    title: "Dropbox",
    description:
      "Connect Dropbox to utilize external storage for your files and data.",
  },
  {
    type: "discord",
    image: DiscordLogoIMG,
    title: "Discord",
    description:
      "Connect with Discord to receive real-time notifications and updates in your Discord group.",
  },
  {
    type: "hubspot",
    image: HubspotLogoIMG,
    title: "Hubspot",
    description:
      "Integrate HubSpot to automate CRM and marketing tasks, while also acquiring clients and managing deals.",
  },
];

const IntegrationsPage = () => {
  return (
    <Box sx={{ py: 4, px: 3 }}>
      <Typography variant="h4" mb={2}>
        Third Party Integrations
      </Typography>

      {/* Integrations */}
      <Grid container spacing={2} mb={5}>
        {integrations.map(({ type, image, title, description }) => {
          return (
            <Grid key={type} item xs={12} lg={6}>
              <Stack
                direction="column"
                justifyContent="space-between"
                sx={{
                  pt: 3,
                  pb: 2,
                  px: 4,
                  border: "1px solid #E9EBEB",
                  position: "relative",
                  height: "100%",
                }}
              >
                <Box>
                  <Box
                    component="img"
                    src={image}
                    alt={title}
                    width={150}
                    height={30}
                    sx={{ objectFit: "contain", objectPosition: "left center" }}
                    mb={3}
                  />

                  <Typography mb={4}>{description}</Typography>
                </Box>

                <Box>
                  <Button variant="contained">Connect</Button>
                </Box>
              </Stack>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default IntegrationsPage;
