import { Box, Button, ButtonGroup } from "@mui/material";
import React from "react";

import { ReactComponent as ColumnIcon } from "assets/icons/column-icon.svg";
import { ReactComponent as GridIcon } from "assets/icons/grid-icon.svg";
import { ReactComponent as ListIcon } from "assets/icons/list-icon.svg";

import ClientsListActions from "./ClientsListActions";
import ClientsListTable from "./ClientsListTable/ClientsListTable";

const ClientsList = () => {
  return (
    <Box sx={{ py: 4, pt: 3, pl: 3 }}>
      <Box py={1} pr={3} mb={2} bgcolor="#fff">
        <ClientsListActions />

        {/* View Switch */}
        <Box mt={1.5}>
          <ButtonGroup
            sx={{
              ".MuiButton-root": {
                py: "6px!important",
                px: "12px!important",
              },
              svg: {
                width: 20,
                height: 20,
              },
            }}
          >
            <Button variant="contained">
              <ListIcon />
            </Button>
            <Button>
              <ColumnIcon />
            </Button>
            <Button>
              <GridIcon />
            </Button>
          </ButtonGroup>
        </Box>
      </Box>

      <ClientsListTable />
    </Box>
  );
};

export default ClientsList;
