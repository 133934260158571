import { Box } from "@mui/material";
import React from "react";

import ProjectsTimelineActions from "./ProjectsTimelineActions";
import ProjectsVisTimeline from "./ProjectsVisTimeline/ProjectsVisTimeline";

const ProjectsTimeline = () => {
  return (
    <Box sx={{ py: 4, px: 3 }}>
      <Box pb={1} mb={2} bgcolor="#fff">
        <ProjectsTimelineActions />
      </Box>

      <ProjectsVisTimeline />
    </Box>
  );
};

export default ProjectsTimeline;
