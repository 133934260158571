import { Box, Button, Divider, Stack } from "@mui/material";
import { Form as FormikForm, FormikProvider, useFormik } from "formik";
import React from "react";
import * as yup from "yup";

import FormikTextField from "components/forms/FormikTextField";

const ChangePasswordForm = () => {
  const handleSubmit = async (values: any) => {
    console.log(values);
  };

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      repeatNewPassword: "",
    },
    validationSchema: yup.object({
      oldPassword: yup.string().required(),
      newPassword: yup.string().required(),
      repeatNewPassword: yup.string().required(),
    }),
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <FormikProvider value={formik}>
      <FormikForm>
        <Box p={2}>
          <FormikTextField
            name="oldPassword"
            type="password"
            label="Old Password"
          />
          <FormikTextField
            name="newPassword"
            type="password"
            label="New Password"
          />
          <FormikTextField
            name="repeatNewPassword"
            type="password"
            label="Repeat New Password"
          />
        </Box>
        <Divider />
        <Stack direction="row" justifyContent="end" py={1} px={2}>
          <Button type="submit" variant="contained">
            Save
          </Button>
        </Stack>
      </FormikForm>
    </FormikProvider>
  );
};

export default ChangePasswordForm;
