import { Box } from "@mui/material";
import React from "react";

import DotsBackgroundImage from "assets/icons/dots-background-icon.svg";

const ClientsWorkflows = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        background: `url('${DotsBackgroundImage}') repeat center center`,
      }}
    ></Box>
  );
};

export default ClientsWorkflows;
