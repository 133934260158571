import React, { FC } from "react";
import { Column } from "react-table";

import Table from "components/Table";
import { EmailFormatter } from "components/Table/formatters/EmailFormatter";
import { priceFormatter } from "components/Table/formatters/priceFormatter";

import { EmployeeFormatter } from "./components/EmployeeFormatter";
import { EmployeeWorkloadFormatter } from "./components/EmployeeWorkloadFormatter";

interface IEmployeesListTable {}

const EmployeesListTable: FC<IEmployeesListTable> = () => {
  const columns: Column<any>[] = [
    {
      accessor: "name",
      Header: "Employee",

      Cell: ({ value }) => <EmployeeFormatter name={value} />,

      padding: 0,
      width: 250,
    },
    {
      accessor: "email",
      Header: "Email",

      Cell: ({ value }) => <EmailFormatter email={value} />,

      padding: 0,
      width: 300,
    },
    {
      accessor: "salary",
      Header: "Salary",

      Cell: ({ value }) => <>{priceFormatter(50000)}</>,

      width: 200,
    },
    {
      accessor: "workload",
      Header: "Workload",

      Cell: ({ value }) => <EmployeeWorkloadFormatter workload={value} />,

      padding: 0,
      width: 200,
    },
    // {
    //   accessor: "contactEmail",
    //   Header: "Contact Email",

    //   width: 250,
    // },
  ];

  return (
    <>
      <Table
        data={[
          {
            name: "Andrej Kitanovic",
            email: "kitanovicandrej213@gmail.com",
            salary: 50000,
            workload: 42,
          },
        ]}
        // data={data}
        columns={columns}
        // sort={sortOptions}
        // pagination={paginationOptions}
        // status="loading"
      />
    </>
  );
};

export default EmployeesListTable;
