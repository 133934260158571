import {
  FacebookOutlined,
  GitHub,
  Instagram,
  LinkedIn,
  Twitter,
} from "@mui/icons-material";
import { Box, Button, Divider, InputAdornment, Stack } from "@mui/material";
import { Form as FormikForm, FormikProvider, useFormik } from "formik";
import React from "react";
import * as yup from "yup";

import FormikTextField from "components/forms/FormikTextField";

const ProfileSocialInformationForm = () => {
  const handleSubmit = async (values: any) => {
    console.log(values);
  };

  const formik = useFormik({
    initialValues: {
      facebook: "",
      instagram: "",
      twitter: "",
      linkedin: "",
      github: "",
    },
    validationSchema: yup.object({
      facebook: yup.string().optional(),
      instagram: yup.string().optional(),
      twitter: yup.string().optional(),
      linkedin: yup.string().optional(),
      github: yup.string().optional(),
    }),
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <FormikProvider value={formik}>
      <FormikForm>
        <Box p={2}>
          <FormikTextField
            name="facebook"
            label="Facebook"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FacebookOutlined sx={{ color: "#4267B2" }} />
                </InputAdornment>
              ),
            }}
          />
          <FormikTextField
            name="instagram"
            label="Instagram"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Instagram
                    sx={{
                      color: "#d6249f",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
          <FormikTextField
            name="twitter"
            label="Twitter"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Twitter sx={{ color: "#1DA1F2" }} />
                </InputAdornment>
              ),
            }}
          />
          <FormikTextField
            name="linkedin"
            label="Linkedin"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LinkedIn sx={{ color: "#0077b5" }} />
                </InputAdornment>
              ),
            }}
          />
          <FormikTextField
            name="github"
            label="GitHub"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <GitHub sx={{ color: "#333" }} />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Divider />
        <Stack direction="row" justifyContent="end" py={1} px={2}>
          <Button type="submit" variant="contained">
            Save
          </Button>
        </Stack>
      </FormikForm>
    </FormikProvider>
  );
};

export default ProfileSocialInformationForm;
