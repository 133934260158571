import { Stack } from "@mui/material";
import { FC } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";

import { WithChildren } from "utils/types";

export const ErrorFallback = ({ resetErrorBoundary }: FallbackProps) => {
  return (
    <Stack direction="column" alignItems="center" height="100vh" p={2}></Stack>
  );
};

const GlobalErrorBoundary: FC<WithChildren> = ({ children }) => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={(error, info) => {
        // we could send some error data to external system like DataDog etc. here
        console.error({ error, info });
      }}
      onReset={() => (window.location.pathname = "/dashboard")}
    >
      {children}
    </ErrorBoundary>
  );
};

export default GlobalErrorBoundary;
