import React from "react";

import Header from "components/pages/Header/Header";

import RoadmapCanny from "./RoadmapCanny/RoadmapCanny";

const RoadmapPage = () => {
  return (
    <>
      <Header label="Roadmap" />

      <RoadmapCanny />
    </>
  );
};

export default RoadmapPage;
