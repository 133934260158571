import {
  KeyboardDoubleArrowLeft as KeyboardDoubleArrowLeftIcon,
  KeyboardDoubleArrowRight as KeyboardDoubleArrowRightIcon,
} from "@mui/icons-material";
import { Box, Drawer, IconButton, Toolbar } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

// LOGO:
import logo from "assets/logos/logo.svg";

import SidebarMenu from "./SidebarMenu";

interface DesktopSidebarDrawerProps {
  wideDrawerWidth: number;
  menuDrawerWidth: number;
  isDesktopDrawerShrinked: boolean;
  toggleDesktopDrawer: () => void;
}

const DesktopSidebarDrawer = ({
  wideDrawerWidth,
  menuDrawerWidth,
  isDesktopDrawerShrinked,
  toggleDesktopDrawer,
}: DesktopSidebarDrawerProps) => {
  return (
    <Drawer
      variant="permanent"
      sx={{
        "& .MuiDrawer-paper": {
          width: !isDesktopDrawerShrinked ? wideDrawerWidth : menuDrawerWidth,
          transition: "width 100ms ease-out",
          // overflow: "hidden",
          border: "none",
          borderRight: "1px solid #E9EBEB",
        },
      }}
    >
      <Box sx={{ height: "100%" }}>
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: !isDesktopDrawerShrinked
              ? "space-between"
              : "center",
            alignItems: "center",
          }}
          disableGutters
        >
          {!isDesktopDrawerShrinked && (
            <Box sx={{ pl: 1 }}>
              <Link to="/dashboard" style={{ textDecoration: "none" }}>
                <img
                  alt="CompaVion Logo"
                  style={{
                    height: 28,
                    objectFit: "contain",
                    objectPosition: "0 50%",
                  }}
                  src={logo}
                />
              </Link>
            </Box>
          )}

          <Box sx={{ px: 1 }}>
            <IconButton color="primary" onClick={toggleDesktopDrawer}>
              {isDesktopDrawerShrinked ? (
                <KeyboardDoubleArrowRightIcon />
              ) : (
                <KeyboardDoubleArrowLeftIcon />
              )}
            </IconButton>
          </Box>
        </Toolbar>

        <SidebarMenu />
      </Box>
    </Drawer>
  );
};

export default DesktopSidebarDrawer;
