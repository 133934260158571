import { Chip, Stack } from "@mui/material";

export const statusColor = {
  lead: "warning",
  client: "success",
  lost: "error",
};

export const ClientStatusFormatter = ({
  status,
}: {
  status: "lead" | "client" | "lost";
}) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={{
        height: "100%",
        // color: statusColor[status],
        // fontWeight: 700,
        textTransform: "capitalize",

        // transition:
        //   "background-color 150ms ease-in-out, color 150ms ease-in-out",
        // ":hover": {
        //   bgcolor: statusColor[status],
        //
        // },
      }}
    >
      <Chip
        sx={
          {
            // padding: "0 4px",
            //   bgcolor: statusColor[status],
            //   color: "#fff",
            // borderRadius: 4,
          }
        }
        //@ts-expect-error
        color={statusColor[status]}
        label={status}
      />
    </Stack>
  );
};
